import React from "react";
import video1 from '../videos/rotationdna.mp4';
import '../DnaTestPage/dna.css'
import bgimage from '../images/headerlogo.webp';

export const DnaHeaderSection = () => {
  return (

    <>
      <div class="home">
        <video muted loop autoPlay>
          <source
            src={video1}
            type="video/mp4"
          />
        </video>
        <div className="centered">
          <img src={bgimage} className='bgimgcontent' alt="" />
        </div>
        <h1 className="sportxdna" >BIO HACK YOUR</h1> <h1 className="sportxdna2">PERFORMANCE</h1>
      </div>
    </>
  );
};
export default DnaHeaderSection;