import React, { useEffect, useState } from "react";

import image1 from "../images/Homepageimg/1.webp";
import image2 from "../images/Homepageimg/2.webp";
import image3 from "../images/Homepageimg/3.webp";
import image4 from "../images/Homepageimg/4.webp";
import image5 from "../images/Homepageimg/5.webp";
import image6 from "../images/Homepageimg/6.webp";

import popupimgdesktop from "../images/Homepageimg/Pop up form.webp";
// import popupimgmobile from "../images/Homepageimg/young-fitness-man-studio 1_11zon.webp";
import { Link } from "react-router-dom";

// import logoreponsive from "../images/headerlogo.webp";
import logoreponsive from "../images/logo.png";


// import bgimage from '../images/headerlogo.webp';

export const HeaderSliderSection = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  const openModelform = () => {
    setShowModal2(true);
    setShowModal(false);
  };

  const closeModal2 = () => {
    setShowModal2(false);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {

      setShowModal(true);

}, 4000);

return () => clearTimeout(timeoutId);
}, []);

  // model form effect
  useEffect(() => {
    const isMobileScreen = window.innerWidth <= 768;
    if (isMobileScreen) {
      const timeoutId = setTimeout(() => {
        setShowModal2(true);
      }, 4000);
      return () => clearTimeout(timeoutId);
    }
  }, []);

  // Get the current URL
  const currentUrl = window.location.href;

  // Create a URLSearchParams object from the URL
  // const urlSearchParams = new URLSearchParams(currentUrl);

  const inputString = currentUrl;
  // Split the string into an array using a delimiter (in this case, a comma)
  const splitArray = inputString.split("?");
  var utmlinks = splitArray[1];

  var utmlink = "";

  if (utmlinks) {
    utmlink = utmlinks;
  }

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    pagetype: "Popup form",
    utm_link: utmlink,
  });
  const [isHidden, setIsHidden] = useState(true);
  const [isDiv2Hidden, setIsDiv2Hidden] = useState(true);

  const handleClearInputs = () => {
    setFormData({ name: "", number: "", email: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsHidden(!isHidden);

    if (!isValidEmail(formData.email)) {
      alert("Enter a valid email address");
      return;
    }

    try {
      const response = await fetch(
        "https://urbanfitedge.com/EdgeApi/contactus.php",
        {
          method: "POST",
          body: JSON.stringify(formData),
        }
      );
      if (response.ok) {
        handleClearInputs();
        setIsHidden(isHidden);
        setIsDiv2Hidden(!isDiv2Hidden);
       
        setTimeout(() => {
          // After the operation is complete, set isLoading back to false
          setIsDiv2Hidden(isDiv2Hidden);
          setShowModal(false);
        }, 3000);
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  function isValidEmail(email) {
    // Regular expression pattern for a valid email address
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // setFormData({ ...formData, [name]: value });

    if (name === "number") {
      const inputnum = value.replace(/[^0-9.]/g, "").substring(0, 10);
      setFormData({ ...formData, [name]: inputnum });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const numItems = 6; // Total number of carousel items

  useEffect(() => {
    const interval = setInterval(() => {
      // Increment activeIndex cyclically
      setActiveIndex((prevIndex) => (prevIndex + 1) % numItems);
    }, 8000); // Change the interval as per your requirement

    return () => clearInterval(interval);
  }, []); // Run only once on component mount

  return (
    <section id="hero" className="hero">
      <div className="hero-container ">
        <div id="carouselExampleCaptions" className="carousel slide ">
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="0"
              className={`sliderbtn ${activeIndex === 0 ? "active" : ""}`}
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="1"
              className={`sliderbtn ${activeIndex === 1 ? "active" : ""}`}
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="2"
              className={`sliderbtn ${activeIndex === 2 ? "active" : ""}`}
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="3"
              className={`sliderbtn ${activeIndex === 3 ? "active" : ""}`}
              aria-label="Slide 4"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="4"
              className={`sliderbtn ${activeIndex === 4 ? "active" : ""}`}
              aria-label="Slide 5"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="5"
              className={`sliderbtn ${activeIndex === 5 ? "active" : ""}`}
              aria-label="Slide 6"
            ></button>
          </div>
          <div className="carousel-inner">
            <div
              className={`carousel-item ${activeIndex === 0 ? "active" : ""}`}
            >       
             <img src={image1} className="d-block w-100" alt="..." />  
              <div className="carousel-caption ">
                <h5>
                  <button
                    className="headerbutton animate__animated animate__fadeInDown"
                    onClick={openModelform}
                  >
                    BOOK FREE TRIAL
                  </button>
                </h5>
              </div>
            </div>
            <div
              className={`carousel-item ${activeIndex === 1 ? "active" : ""}`}
            >
              <img src={image2} className="d-block w-100" alt="..." />
              {/* <div className="centered"> <img src={bgimage} className='bgimgcontent' alt=""/> </div> */}
              <div className="carousel-caption ">
                <h5>
                  <button
                    className="headerbutton animate__animated animate__fadeInDown"
                    onClick={openModelform}
                  >
                    {/* <Link to="/about" className="readmorebtns">Read More </Link> */}
                    BOOK FREE TRIAL
                  </button>
                </h5>
              </div>
            </div>
            <div
              className={`carousel-item ${activeIndex === 2 ? "active" : ""}`}
            >
              {/* <h1 className="sportx" >SPORTS SPECIFIC </h1> <h1 className="sportx2">TRAINING</h1> */}
              <img src={image3} className="d-block w-100" alt="..." />
              {/* <div className="centered"><img src={bgimage} className='bgimgcontent' alt=""/></div> */}
              <div className="carousel-caption ">
                <h5>
                  <button
                    className="headerbutton animate__animated animate__fadeInDown"
                    onClick={openModelform}
                  >
                    {/* <Link to="/about" className="readmorebtns" >Read More </Link> */}
                    BOOK FREE TRIAL
                  </button>
                </h5>
                {/* <p className="sliderdetails">
                                    <span className='firstletter2'>
                                        Sport-specific
                                    </span>
                                    training to help you reach your athletic goals.
                                </p> */}
              </div>
            </div>
            <div
              className={`carousel-item ${activeIndex === 3 ? "active" : ""}`}
            >
              {/* <h1 className="sportx" >FAT LOSS</h1> <h1 className="sportx2">TRAINING</h1> */}
              <img src={image4} className="d-block w-100" alt="..." />
              {/* <div className="centered"><img src={bgimage} className='bgimgcontent' alt=""/></div> */}
              <div className="carousel-caption">
                <h5>
                  <button
                    className="headerbutton animate__animated animate__fadeInDown"
                    onClick={openModelform}
                  >
                    {/* <Link to="/about" className="readmorebtns" >Read More </Link> */}
                    BOOK FREE TRIAL
                  </button>
                </h5>
                {/* <p className="sliderdetails" >
                                    <span className='firstletter2'>
                                        Fat loss </span>
                                    training designed to help you achieve your fitness goals.
                                </p> */}
              </div>
            </div>
            <div
              className={`carousel-item ${activeIndex === 4 ? "active" : ""}`}
            >
             <img src={image5} className="d-block w-100" alt="..." />
             <div className="carousel-caption ">
                <h5>
                  <button
                    className="headerbutton animate__animated animate__fadeInDown"
                    onClick={openModelform}
                  >
                    {/* <Link to="/about" className="readmorebtns" >Read More </Link>  */}
                    BOOK FREE TRIAL
                  </button>
                </h5>
                
              </div>
            </div>
            <div
              className={`carousel-item ${activeIndex === 5 ? "active" : ""}`}
            >
              {/* <h1 className="sportx" >PERSONAL</h1> <h1 className="sportx2">TRAINING</h1> */}
              <img src={image6} className="d-block w-100" alt="..." />
              {/* <div className="centered"><img src={bgimage} className='bgimgcontent' alt=""/></div> */}
              <div className="carousel-caption ">
                <h5>
                  <button
                    className="headerbutton animate__animated animate__fadeInDown"
                    onClick={openModelform}
                  >
                    {/* <Link to="/about" className="readmorebtns" >Read More </Link> */}
                    BOOK FREE TRIAL
                  </button>
                </h5>
                
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>

      <div
        id="myModal"
        className={`desktopviewpopup modal fade ${showModal ? "show" : ""}`}
        style={{ display: showModal ? "block" : "none" }}
      >
        <div class="modal-dialog mdalog modal-lg animate__animated animate__fadeInDown">
          <div class="modal-header closebtn">
            <button
              type="button"
              class="close-btn closebtnpopup"
              aria-label="Close"
              onClick={closeModal}
            >
              {" "}
              X
            </button>
          </div>
          <Link to="/contactus">
            <div class="modal-content">
              <img src={popupimgdesktop} className="popupimg" alt="" />
            </div>
          </Link>
        </div>
      </div>

      {/* mobile model mobileview*/}

      <div
        id="myModal"
        className={` modal fade ${showModal2 ? "show" : ""}`}
        style={{ display: showModal2 ? "block" : "none", height: "100%" }}
      >
        <div class="modal-dialog mdalog animate__animated animate__fadeInDown">
          <img src={logoreponsive} className="responsivelogomodel" alt="" />

          <div class="modal-header closebtn">
            <button
              type="button"
              class="close-btn closebtnpopup clsbtn2"
              aria-label="Close"
              onClick={closeModal2}
            >
              {" "}
              X
            </button>
          </div>

          <div class="modal-content ">
            <div class="pickgradient">
              {/* <img className="imgmobilepopup" src={popupimgmobile} alt="" /> */}
            </div>

            <div className="container text-center header" style={{ zIndex: 1 }}>
              <p style={{color:'black'}}>
                {/* Training at <span className='punes'> Pune's </span><br/> */}
                Book Free 1-month Trial Session
              </p>
            </div>

            <div className="container text-center modelformresponsive">
              <div className="form-group mt-5">
                <input
                  type="text"
                  className="form-control customeinput"
                  name="name"
                  id="exampleInputEmail1"
                  value={formData.name}
                  onChange={handleInputChange}
                  aria-describedby="emailHelp"
                  placeholder="Name"
                />
              </div>
              <div className="form-group mt-5">
                <input
                  type="text"
                  className="form-control customeinput"
                  name="number"
                  id="exampleInputPassword1"
                  value={formData.number}
                  onChange={handleInputChange}
                  placeholder="Number"
                />
              </div>
              <div className="form-group mt-5">
                <input
                  type="email"
                  className="form-control customeinput"
                  name="email"
                  id="exampleInputPassword1"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>

              <center className="mt-4">
              
              </center>
              <center className="modelsubmitbtn">
                <button
                  type="button"
                  className="submitcontactus"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </center>

              <div className="my-3 msgsection">
                <div
                  className="container"
                  style={{ display: isHidden ? "none" : "block" }}
                >
                  <div className="sound-wave">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                <div className="error-message"></div>
                <div
                  className="sent-message modelmsg"
                  style={{ display: isDiv2Hidden ? "none" : "block" }}
                >
                  {" "}
                  Thank you!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default HeaderSliderSection;
