import React from "react";

import trust from "../images/Icon/Trust.png"
import vector from "../images/Icon/Vector.png"
import dnabody from "../images/Dnatest/DNA_01 1.webp";
import dnabody2 from "../images/Dnatest/Aging_01 2.webp";

export const Dnalamifit = () => {
    return (
        <section id="about" class="about">
            <div class="container ">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="dnasection2heading">  <img src={trust} alt="" /> DNA-powered health optimization.</h1>
                        <p className="dnaheadingdetail">
                            Urbanfit Edge in collaboration with LamiFit offers a comprehensive DNA testing service that provides you with personalized insights into your health and fitness. We use your DNA to predict your risk of developing certain diseases and to create a customized plan to help you reach your health and fitness goals.
                        </p>
                    </div>
                </div>
                <div className="row ">
                    <div className="col-md-4 col-sm-12 col-xs-12 ">
                        <div className="section-headline text-center">
                            <button className="dnsbtns">Reverse Aging</button>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <div className="section-headline text-center">
                            <button className="dnsbtns">Achieve Peak Performance</button>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <div className="section-headline text-center">
                            <button className="dnsbtns">Reverse & Control Diseases</button>
                        </div>
                    </div>
                </div>


                <div className="row mt-5">
                    <div className="col-md-12 col-sm-4 col-xs-12">
                        <div className="single-well">
                            <h1 className="dnasection2heading">
                                <img src={vector} alt="" />  How it works ?
                            </h1>
                            <div className="row iconrowdna mt-5">
                                <div class="column" >
                                    <span id="boot-icon" class="bi bi-cloud-arrow-up-fill iconsizedna dnsicobtn" ></span>
                                    <p className="dnaicondetail">Step-1</p>
                                    <p className="icocontent">Test your health. </p>

                                </div>
                                <div class="column" >
                                    <span id="boot-icon" class="bi bi-lightbulb-fill iconsizedna dnsicobtn"></span>
                                    <p className="dnaicondetail">Step-2</p>
                                    <p className="icocontent">Get personalized insights. </p>

                                </div>
                                <div class="column" >
                                    <span id="boot-icon" class="bi bi-file-earmark-fill iconsizedna dnsicobtn"></span>
                                    <p className="dnaicondetail">Step-3</p>
                                    <p className="icocontent">Boost your health.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row ">
                    <h1 className="dnasection2heading mt-5">
                        Get your DNA tested to unlock the power of your DNA & reach the pinnacle of health.
                    </h1>
                    <div className="col-md-6 col-sm-6 col-xs-12 mt-5">
                        <p className="dnadetails">
                            Our DNA test helps you become the best possible version of yourself. We provide a personalized, easier, and more effective solution to health and fitness, entirely customized to your DNA profile. Whatever your goal, our DNA Genetic Test can personalize your path to a healthier and happier life.

                        </p>
                    </div>
                    <div className="col-md-6 col-sm-4 col-xs-12 mt-5">
                        <img src={dnabody} alt="Contaentdetail1" className="contentsmallimages" />
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-md-6 col-sm-4 col-xs-12 mt-5">
                        <img src={dnabody2} alt="Contaentdetail1" className="contentsmallimages" />
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12 mt-5">
                        <h1 className="dnasection2heading">
                            Are you aging faster than you should?
                        </h1>
                        <p className="dnadetails">
                            Find out your cellular age with the Urbanfit Epigenetic Test. Discover ways to improve it, so your inner self stays youthful.
                        </p>
                    </div>

                </div>
            </div>
        </section>

    );
};
export default Dnalamifit;