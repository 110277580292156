import Navbar from '../Componants/Navbar';
import Footer from '../Componants/Footer';
import '../Pages/Gallery.css';
import { Link } from 'react-router-dom';
import img1 from '../images/Rectangle 58.webp';
import img2 from '../images/Rectangle 56.png';
import img3 from '../images/Rectangle 59.png';


const PersonalTraining = () => {
    return (
        <>
            <Navbar />
            <main id="main">
                <section className="inner-page">
                    <div className="container multipleimgsection">
                        <div className='row'>
                            <div className='col-md-12'>
                                <Link to='/gallary'>  
                                <h1 className='h1heading'>
                                    <span class='bi bi-chevron-left gallerybackbtn'></span>
                                     <span className='Gallerybackbtn1'>Personal</span>
                                      <span className='Gallerybackbtn2'>Area</span> </h1>
                                </Link>
                            </div>
                        </div>
                        <h1 className='mulitipleimgpagename d-none d-md-block d-lg-block'>GALLERY</h1>
                        <div className='row '>
                            <div className='col-md-2'>
                            </div>
                            <div className='col-md-10'>
                                <div className='row mt-3'>
                                    <div className='col-md-4'>
                                        <img src={img1} alt="" className='imgdivmulitiple' />
                                    </div>
                                    <div className='col-md-4'>
                                        <img src={img2} alt="" className='imgdivmulitiple' />
                                    </div>
                                    <div className='col-md-4'>
                                        <img src={img3} alt="" className='imgdivmulitiple' />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <img src={img1} alt="" className='imgdivmulitiple' />
                                    </div>
                                    <div className='col-md-4'>
                                        <img src={img2} alt="" className='imgdivmulitiple' />
                                    </div>
                                    <div className='col-md-4'>
                                        <img src={img3} alt="" className='imgdivmulitiple' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}
export default PersonalTraining;