import React from "react";
// import { Link } from "react-router-dom";
import bodyroationvideo from '../videos/DNAVideo_.mp4';

export const DnaHealthdata = () => {
  return (
    // id="services" 
    <section id="services" class="services">
      <div class="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="dnasection2heading2">
              Health data can be complex and confusing, but our platform makes it easy to understand and use. </h1>
            <p className="dnaheadingdetail">
              Our health reports provide you with actionable insights into your health, so you can make informed decisions about your lifestyle and well-being.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <video muted loop autoPlay className="bodrationsection">
          <source src={bodyroationvideo} type="video/mp4" />
        </video>
      </div>
    </section>

  );
};
export default DnaHealthdata;