import React from "react";
import { Link } from "react-router-dom";

export const Dnadietandnutrition = () => {

  return (
    // id="services" 
    <section id="services" class="services">
      <div className="container">
      <div className="row mt-5 footergetstart">
        <div className="col-md-6">
          <center className="contentfooter">Become the best possible version of
            yourself with Urbanfit.</center>
        </div>
        <div className="col-md-6">
          <Link to="/contactus">
            <button type="button" className="getstartbtn">Get Started</button>
          </Link>
        </div>
      </div>
      </div>
    </section>
  );
};

export default Dnadietandnutrition;