import Navbar from '../Componants/Navbar';
import Footer from '../Componants/Footer';
import '../Pages/Gallery.css';
import { Link } from 'react-router-dom';
// import Strengtharea from '../images/galleryimg.png';

import strrengtharea from '../images/Galleryimgas/Rectangle 80_1_11zon.webp'
import FunctionalTraining from '../images/Galleryimgas/Rectangle 81_2_11zon.webp';
import Runningarea from '../images/Galleryimgas/Rectangle 82_3_11zon.webp';
import PersonalTraining from '../images/Galleryimgas/Rectangle 85_4_11zon.webp';


const Gallary = () => {
    return (
        <>
            <Navbar />
            <main id="main">
                <section className="inner-page">
                    <div class="row">
                        <div class="col-md-2">
                            <h1 className='pagename d-none d-md-block d-lg-block'>GALLERY</h1>
                        </div>
                        <div class="col-md-10">
                            <div className='row'>
                                <div className='col-md-6 mb-3'>
                                    <Link to="/Gallery/Strength-Area" >
                                        <img src={strrengtharea} className='imgdiv' alt='' />
                                        <h1 className="strenght1" >Strength</h1> <h1 className="strenght2 ">area</h1>
                                    </Link>
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <Link to="/Gallery/Functional-Training" >
                                        <img src={FunctionalTraining} className='imgdiv' alt='' />
                                        <h1 className="functionaltraining1" >Functional</h1> <h1 className="functionaltraining2 ">Training</h1>
                                    </Link>
                                </div>
                            </div>
                            <div className='row mt-5'>
                                <div className='col-md-6 mb-3'>
                                    <Link to="/Gallery/Running-Area" >
                                        <img src={Runningarea} className='imgdiv' alt='' />
                                        <h1 className="runningarea1" >Running</h1> <h1 className="runningarea2 ">area</h1>
                                    </Link>
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <Link to="/Gallery/Personal-Training" >
                                        <img src={PersonalTraining} className='imgdiv' alt='' />
                                        <h1 className="personaltraining1" >Personal</h1> <h1 className="personaltraining2 ">Training</h1>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}
export default Gallary;