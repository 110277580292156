import Navbar from '../Componants/Navbar';
import Footer from '../Componants/Footer';
import '../Pages/Gallery.css';
import { Link } from 'react-router-dom';
import image1 from '../images/Gallery/IMG_1257_1_11zon.webp';
import image2 from '../images/Gallery/IMG_1258_2_11zon.webp';
import image3 from '../images/Gallery/IMG_1259_3_11zon.webp';
import image4 from '../images/Gallery/IMG_1260_4_11zon.webp';
import image5 from '../images/Gallery/IMG_1261_5_11zon.webp';
import image6 from '../images/Gallery/IMG_1262_6_11zon.webp';
// import image7 from '../images/Gallery/IMG_1263_7_11zon.webp';
// import image8 from '../images/Gallery/IMG_1264_8_11zon.webp';
// import image9 from '../images/Gallery/IMG_1265_9_11zon.webp';
// import image10 from '../images/Gallery/IMG_1266_10_11zon.webp';



const StrengthArea = () => {
    return (
        <>
            <Navbar />
            <main id="main">
                <section className="inner-page">
                    <div className="container multipleimgsection">
                        <div className='row'>
                            <div className='col-md-12'>
                                <Link to='/gallary'>
                                    <h1 className='h1heading'>
                                        <span class='bi bi-chevron-left gallerybackbtn'></span>
                                         <span className='Gallerybackbtn1'>Strength</span> 
                                         <span className='Gallerybackbtn2'>area</span> </h1>
                                </Link>
                            </div>
                        </div>
                        <h1 className='mulitipleimgpagename d-none d-md-block d-lg-block'>GALLERY</h1>
                        <div className='container'>
                            <div className='row mt-3'>
                                <div className='col-md-4'>
                                    <img src={image1} alt="" className='imgdivmulitiple' />
                                </div>
                                <div className='col-md-4'>
                                    <img src={image2} alt="" className='imgdivmulitiple' />
                                </div>
                                <div className='col-md-4'>
                                    <img src={image3} alt="" className='imgdivmulitiple' />
                                </div>
                            </div>
                            <div className='row mt-5'>
                                <div className='col-md-4'>
                                    <img src={image4} alt="" className='imgdivmulitiple' />
                                </div>
                                <div className='col-md-4'>
                                    <img src={image5} alt="" className='imgdivmulitiple' />
                                </div>
                                <div className='col-md-4'>
                                    <img src={image6} alt="" className='imgdivmulitiple' />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}
export default StrengthArea;