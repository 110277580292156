import React from "react";
import icon1 from "../images/playground 1.png"
import icon2 from "../images/MicrosoftTeams-2.png"
import icon3 from "../images/running-track 2.png"

export const Services = () => {
    return (
      // id="services" 
        <section id="services" class="services">
        <div class="container">
        <hr className="borderline " />
          <div class="section-title">
            <h2>Be Stronger Than Your Excuse</h2>
            <p>After you decide to start training, we will make sure you get the best fitness program. 
            Our sports experts and the latest sports equipment are the winning combinations.</p>
          </div>
  
          <div class="row mt-5">
            <div class="col-lg-4 col-md-6 icon-box">
              <div class="icon"><img className="serviceimg" src={icon1} /></div>
              <h4 class="title"><a href="javascript:void(0)">Spacious Center </a></h4>
              <p class="description">
              The 10000 sq. ft. open training center is the perfect place to train for any sport 
              </p>
            </div>
            <div class="col-lg-4 col-md-6 icon-box">
              <div class="icon"><img className="serviceimg" src={icon2} /></div>
              <h4 class="title"><a href="javascript:void(0)">Immersive Training </a></h4>
              <p class="description">
              4-sided open training center provides athletes with a unique and immersive training experience .
                </p>
            </div>
            <div class="col-lg-4 col-md-6 icon-box">
              <div class="icon"><img className="serviceimg" src={icon3} /></div>
              <h4 class="title"><a href="javascript:void(0)">Dedicated S&A space </a></h4>
              <p class="description">
              3000 sq. ft. speed and agility area for the best training experience </p>
            </div>
          </div>
          <hr className="borderline " />
        </div>
      </section>
    );
};
export default Services;