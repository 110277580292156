import Dnanavbar from '../Componants/DNAnavbar';
import DnaHeaderSection from '../Componants/DnaHeaderSection';
import Dnalamifit from '../Componants/Dnalamifit';
import DnaHealthdata from '../Componants/DnaHealthdata';
import Dnadietandnutrition from '../Componants/Dnadietandnutrition'


const DnaTestContent = () => {
    return (
        <>
          <Dnanavbar/>
          <DnaHeaderSection/>
          <Dnalamifit/>
          <DnaHealthdata/>
          <Dnadietandnutrition/>
        </>
    )
}
export default DnaTestContent;