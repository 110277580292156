import React, {useState } from 'react'
// import { Link } from 'react-router-dom';


export const Contactus = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        number: '',
        interestedin: '',
        pagetype: 'Home Page'
    });
    const [isHidden, setIsHidden] = useState(true);
    const [isDiv2Hidden, setIsDiv2Hidden] = useState(true);

    const handleClearInputs = () => {
        setFormData({ name: '', number: '', email: '', interestedin: '' });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsHidden(!isHidden);

        if (!isValidEmail(formData.email)) {
            alert('Enter a valid email address');
            setIsHidden(isHidden);
            return;
        }


        try {
            const response = await fetch('https://urbanfitedge.com/EdgeApi/contactus.php',
                {
                    method: 'POST',
                    body: JSON.stringify(formData),
                });
            if (response.ok) {
                handleClearInputs();
                setIsHidden(isHidden);
                setIsDiv2Hidden(!isDiv2Hidden);

                setTimeout(() => {
                    // After the operation is complete, set isLoading back to false
                    setIsDiv2Hidden(isDiv2Hidden);
                }, 4000);

            } else {
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    function isValidEmail(email) {
        // Regular expression pattern for a valid email address
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(email);
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        // setFormData({ ...formData, [name]: value });

        if (name === "number") {
            const inputnum = value.replace(/[^0-9.]/g, '').substring(0, 10);
            setFormData({ ...formData, [name]: inputnum });
        }
        else {
            setFormData({ ...formData, [name]: value });
        }

    };
  
    return (
        <>
            <section id="contact" class="contact">
                <div class="container">
                    <div class="section-title">
                        <h2 className="contactheader">Contact us</h2>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form contact-form">
                                <div className="form-group">
                                    <input type="text" name="name" value={formData.name} onChange={handleInputChange} className="form-control" id="name" placeholder="Name" required />
                                </div>
                                <div className="form-group mt-3">
                                    <input type="number" className="form-control" name="number" id="number" value={formData.number} onChange={handleInputChange} placeholder="Mob. No." required />
                                </div>
                                <div className="form-group mt-3">
                                    <input type="email" className="form-control" name="email" id="email" value={formData.email} onChange={handleInputChange} placeholder="Email" required />
                                </div>
                                <div className="form-group mt-3">
                                    <select className="form-control" value={formData.interestedin} onChange={handleInputChange} name="interestedin" id="interestedin" >
                                        <option value="">Interested  In</option>
                                        <option value="Sport-Specific Training">Sport-Specific Training</option>
                                        <option value="Strength & Conditioning">Strength & Conditioning </option>
                                        <option value="Fat Loss">Fat Loss </option>
                                        <option value="Personal Training">Personal Training </option>
                                        <option value="Pre- & Post-Pregnancy">Pre- & Post-Pregnancy </option>
                                        <option value="Senior & Kid Batches">Senior & Kid Batches</option>
                                        <option value="Injury Rehab">Injury Rehab</option>
                                        <option value="Nutrition Counseling">Nutrition Counseling</option>
                                    </select>
                                </div>

                                <div className="my-3">
                                    <div className="loading" style={{ display: isHidden ? 'none' : 'block' }} >
                                    <div className="sound-wave" >
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                    </div>
                                    <div className="error-message"></div>
                                  <div className="sent-message" style={{ display: isDiv2Hidden ? 'none' : 'block' }} > Thank you!</div>
                                </div>
                                <div className="text-center"><button className='submitbtn' onClick={handleSubmit} type="button">Submit</button></div>
                                <div className="my-3">
                                    <center style={{ fontSize: '22px', fontWeight: '800' }} >OR</center>
                                </div>
                                <div className="text-center">
                                    <a href="tel:+919226089711">  <button className='submitbtn' >Call us</button></a></div>
                                {/* </form> */}
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <iframe title="Edge-Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3784.2764334659537!2d73.91210317489156!3d18.47113367068312!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2ebc41eca5289%3A0xcbe454e62ade22c7!2sUrbanFit%20Edge%3A%20Pro%20Athletic%20Development%20%7C%20Semi-personal%20training%20%7C%20Fat%20loss%20Center%20%7C%20Pre%20%26%20Post%20Natal%20Fitness!5e0!3m2!1sen!2sin!4v1696404529319!5m2!1sen!2sin" width="100%" height="380" frameborder="0" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default Contactus;