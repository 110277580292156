import Navbar from '../Componants/Navbar';
import Footer from '../Componants/Footer';

import headerimage from '../images/About/About-Us_04.webp'
import '../Pages/About.css';

import ritik from '../images/About/ritik.webp'
import shergill from '../images/About/shergill.webp'
import Shashank from '../images/About/Shashank.webp';
import gaurav from '../images/About/gaurav.webp';
import marketingimg from '../images/About/marketingimg.webp'

import rightdirection from '../images/Icon/rightdirection.png';
import leftdirection from '../images/Icon/leftdirection.png';
// import updirection from '../images/Icon/updireaction.png';

const About = () => {
    return (
        <>
            <Navbar />
            <main id="main">
                <section className="inner-page">
                    <div className="container">
                        <img src={headerimage} className='aboutheaderimg animate__animated animate__fadeInRight' alt='' />
                        <p className='headerimgcontant animate__animated animate__fadeInUp' >
                            <span className='firstletter2 '> Urbanfit Edge is </span>  a sports athlete development center that combines elements of speed and agility training track, game-specific training, goal-based one-on-one training, dynamic strength training, and plyometrics. We also specify injury prevention and rehabilitation with cardiovascular group activities. Biomechanics video analysis by our professional trainers and health analysis by our nutritionists for overall body weight and variable intensity training. Get to Urbanfit Edge for a personalized fitness analysis and multiskilled strength and conditioning program.
                        </p>
                    </div>

                    <div className="container founderssection">
                        <h1 className='codunderh1'>Meet Our Co-Founders:</h1>
                        <div className="row mt-5">
                            <div className="col-md-1 col-sm-4 col-xs-12">
                                <h1 className='pagenameabout d-none d-md-block d-lg-block'>ABOUT US</h1>
                            </div>

                            {/* Desktop view */}
                            <div className="col-md-4 col-sm-4 col-xs-12 d-none d-md-block d-lg-block">
                                <div className="single-well">
                                    <img src={ritik} alt="Contaentdetail1 " className="contentsmallimages imgceo " style={{'borderRadius':'22px'}}/>
                                </div>
                                <div className="single-well mt-5">
                                    <h4 className="sec-head sechead2">
                                        <span className="aboutnames">Kirat Shergill</span><br/>(Co-Founder)
                                        <img src={rightdirection} alt="test" className="directionicon"  style={{'marginLeft':'30px'}}/>
                                    </h4>
                                    <p className='founderdetails'>
                                        Mr. Kirat is a well-known figure in the Pune fitness and athletic community. He has an impressive track record that only a few trainers in Pune can match. Kirat has previously run successful fitness centers, such as Spiritual Fitness, which was his own venture and transformed the fitness industry in Pune. The entire concept behind EDGE PERFORMANCE Center is Kirat's ideology that revolutionized the fitness industry. Unlike many, Kirat doesn't like training in closed spaces with the AC on. He has always been drawn to nature and believes in training outdoors because, as humans, we are meant to be outside, close to nature.
                                        But there's more to Kirat Shergill that makes him unique. What truly distinguishes him is his profound knowledge of exercise science, a fact that is well-supported by the numerous athletes, clients, personal trainers, and coaches he has successfully collaborated with during his extensive 10+ years of expertise. Kirat Shergill is not only a co-founder but also head of Performance and Sales Head at Edge Performance Center. You can meet him in person on the 19th at Edge Performance Center.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-4 col-xs-12 d-none d-md-block d-lg-block">
                                <div className="single-well ">
                                    <h4 className="sec-head sechead2">
                                        {/* <span class='bi bi-chevron-double-left iconcontent'></span> */}
                                        <img src={leftdirection} alt="test" className="directionicon "/>
                                        <span className="aboutnames" style={{'marginLeft':'30px'}} >Ritik Agarwal</span> (CEO)
                                    </h4>
                                    <p className='founderdetails'>
                                        Mr. Rithik Agarwal is a perfect example of a young, smart, intelligent, and fearless entrepreneur of the modern era. His fearless attitude has led to the success of various ventures in industries like construction, fitness, and wellness.Rithik Sir founded Urbanfit with a vision to revolutionize the health & fitness industry. Between 2017 and 2019, he had a challenging experience with weight loss that damaged his posture and overall health. Determined not to let others go through the same ordeal, Mr. Rithik launched URBANFIT Wellness. Urbanfit Wellness is a fitness startup involved in fitness applications, fitness supplements, operating general fitness studios, and now delving into athletic development. Our company is on a mission to change the way people view the fitness industry by dispelling misconceptions about fitness supplements. With our presence in over 10 supplement stores in Pune, a fitness studio in Viman Nagar, and a Pro-Athletic Development Center in NIBM, we are dedicated to revolutionizing the fitness world.Mr. Rithik is never afraid to take risks. When an idea clicks in his head, he pursues it relentlessly. This spirit led him to co-found EDGE PERFORMANCE Center, where he serves as the Director and CEO.

                                    </p> </div>
                                <div className="single-well mt-5">
                                    <img src={shergill} alt="Contaentdetail2" className="contentsmallimages imgcofounder " style={{'borderRadius':'22px'}}/>

                                    <div className='row mt-5 '>
                                        <div className='col-md-6 col-sm-4 col-xs-12'>
                                            <div className="single-well mt-3">
                                                <img src={Shashank} alt="Contaentdetail1" className="contentsmallimages ceo2img " style={{'borderRadius':'22px'}}/>
                                            </div>
                                        </div>
                                        <div className='col-md-6 col-sm-4 col-xs-12'>
                                            <div className="single-well innercontent2">
                                                <h4 className="sec-head sechead2" style={{'whiteSpace':'nowrap'}}>
                                                    <img src={leftdirection} alt="test" className="directionicon " />
                                                    <span className="aboutnames ceo2name" >Shashank Pratap <br/> Singh</span>
                                                </h4>
                                                <p className='ceo2detail '>
                                                    Shashank has always had a deep passion for sports and fitness. He met Kirat while recovering from his second knee surgery. During his training sessions with Kirat, Shashank became increasingly curious about the science behind every movement of the human body. One day, during a training session, Shashank shared his idea with Kirat about starting a fitness center similar to his previous venture. This conversation sparked an opportunity in his mind. He believed that if he could convey this idea to Rithik, the three of them could create something that would revolutionize the fitness industry. Shashank's late-night call to Mr. Rithik to convey this idea marked the beginning of their vision. The three of them soon met and conceptualized what we now proudly know as EDGE PERFORMANCE CENTER.

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Mobile view */}
                            <div className="col-md-4 col-sm-4 col-xs-12 d-lg-none">
                                <div className="single-well">
                                    <img src={ritik} alt="Contaentdetail1 " className="contentsmallimages imgceo " style={{'borderRadius':'22px'}}/>
                                </div>
                                <div className="single-well mt-5">
                                    <h4 className="sec-head">
                                        <span className="aboutnames">Ritik Agarwal</span> (CEO)
                                    </h4>
                                    <p >
                                        Mr. Rithik Agarwal is a perfect example of a young, smart, intelligent, and fearless entrepreneur of the modern era. His fearless attitude has led to the success of various ventures in industries like construction, fitness, and wellness.Rithik Sir founded Urbanfit with a vision to revolutionize the health & fitness industry. Between 2017 and 2019, he had a challenging experience with weight loss that damaged his posture and overall health. Determined not to let others go through the same ordeal, Mr. Rithik launched URBANFIT Wellness. Urbanfit Wellness is a fitness startup involved in fitness applications, fitness supplements, operating general fitness studios, and now delving into athletic development. Our company is on a mission to change the way people view the fitness industry by dispelling misconceptions about fitness supplements. With our presence in over 10 supplement stores in Pune, a fitness studio in Viman Nagar, and a Pro-Athletic Development Center in NIBM, we are dedicated to revolutionizing the fitness world.Mr. Rithik is never afraid to take risks. When an idea clicks in his head, he pursues it relentlessly. This spirit led him to co-found EDGE PERFORMANCE Center, where he serves as the Director and CEO.

                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4 col-xs-12 d-lg-none">
                                <div className="single-well">
                                    <img src={shergill} alt="Contaentdetail2" className="contentsmallimages imgcofounder " style={{'borderRadius':'22px'}}/>
                                </div>
                                <div className="single-well mt-5">
                                    <h4 className="sec-head">
                                        <span className="aboutnames">Kirat Shergill</span> (Co-Founder)
                                    </h4>
                                    <p >
                                        Mr. Kirat is a well-known figure in the Pune fitness and athletic community. He has an impressive track record that only a few trainers in Pune can match. Kirat has previously run successful fitness centers, such as Spiritual Fitness, which was his own venture and transformed the fitness industry in Pune. The entire concept behind EDGE PERFORMANCE Center is Kirat's ideology that revolutionized the fitness industry. Unlike many, Kirat doesn't like training in closed spaces with the AC on. He has always been drawn to nature and believes in training outdoors because, as humans, we are meant to be outside, close to nature.
                                        But there's more to Kirat Shergill that makes him unique. What truly distinguishes him is his profound knowledge of exercise science, a fact that is well-supported by the numerous athletes, clients, personal trainers, and coaches he has successfully collaborated with during his extensive 10+ years of expertise. Kirat Shergill is not only a co-founder but also head of Performance and Sales Head at Edge Performance Center. You can meet him in person on the 19th at Edge Performance Center.
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-4 col-xs-12 d-lg-none">
                                <div className="single-well">
                                    <img src={Shashank} alt="Contaentdetail1" className="contentsmallimages ceo2img " style={{'borderRadius':'22px'}}/>
                                </div>
                                <div className="single-well mt-5">
                                    <h4 className="sec-head">
                                        <span className="aboutnames ceo2name">Shashank Pratap Singh</span>

                                    </h4>
                                    <p >
                                        Shashank has always had a deep passion for sports and fitness. He met Kirat while recovering from his second knee surgery. During his training sessions with Kirat, Shashank became increasingly curious about the science behind every movement of the human body. One day, during a training session, Shashank shared his idea with Kirat about starting a fitness center similar to his previous venture. This conversation sparked an opportunity in his mind. He believed that if he could convey this idea to Rithik, the three of them could create something that would revolutionize the fitness industry. Shashank's late-night call to Mr. Rithik to convey this idea marked the beginning of their vision. The three of them soon met and conceptualized what we now proudly know as EDGE PERFORMANCE CENTER.
                                    </p>
                                </div>
                            </div>

                        </div>

                        {/* marketin section */}
                        <div className='container'>
                            <div className="row mt-5">
                                <h1 className='codunderh1'>Branding Head :</h1>

                                <div className="col-md-1 col-sm-6 col-xs-12 mt-4">

                                </div>

                                <div className="col-md-4 col-sm-6 col-xs-12 mt-4">
                                    <div className="well-left">
                                        <div className="single-well">
                                            <img src={gaurav} alt="Contaentdetail4" className="contentsmallimages2 imgceo" style={{'borderRadius':'22px'}}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-6 col-xs-12 mt-4">
                                    <div className="well-middle">
                                        <div className="single-well">
                                        <h4 className="sec-head">
                                                  <img src={leftdirection} alt="test" className="directionicon " />
                                                    <span className="aboutnames ceo2name" style={{'marginLeft':'30px'}}>Gaurav Thakkar</span>
                                                </h4>
                                            <p>
                                                Mr. Gaurav is an expert in Branding and marketing with over 20 years of experience in various industries. His acute attention to detail and innovative thinking make him stand out. Gaurav is the creative genius behind EDGE PERFORMANCE CENTER's brand theme and logo. He also conceptualized the supplement store within Edge, a concept that will soon be implemented across every Urbanfit Store.
                                            </p>
                                            <p>
                                                Apart from his professional achievements, Gaurav is a passionate biker and possesses a sharp business insight.
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='container mt-5'>
                            <h1 className='codunderh1' >Marketing Team :</h1>
                            <div className='container'>
                                <div className='text-center mt-5'>
                                    <img src={marketingimg} alt="Contaentdetail4" className="marketingteamimg ceo2img" />
                                </div>
                            </div>
                            <div className='row mt-5'>
                                <div className='col-md-4 col-sm-6 col-xs-12' ></div>
                                <div className='col-md-5 col-sm-6 col-xs-12 text-center teamworkcontaint' >
                                    <span className='temworkmake'>"Teamwork Makes the <br /> <span className='dreamwork'> Dream Work" </span></span>
                                </div>
                                <div className='col-md-3 col-sm-6 col-xs-12' ></div>

                            </div>

                        </div>

                        <div className='container lastaboutsection '>
                            <p className='animate__animated animate__fadeInUp'>
                                Urbanfit's Marketing team is responsible for the banners, flags, and every design detail inside the EDGE PERFORMANCE Center. Headed by Gaurav and managed by Shashank, they are young and innovative in their thinking and execution. The team of designers includes Rishabh, Maithili, Nishad & Sayali. Prachit and Vinit handle digital marketing, Anamika is responsible for content while Sunil manages events and administration.
                            </p>
                            <p className='animate__animated animate__fadeInUp'> This is the team behind EDGE PERFORMANCE CENTER.</p>
                        </div>

                    </div>
                </section>
            </main>
            <Footer />


        </>
    )
}
export default About;