
import { Link } from 'react-router-dom';
import footerlogo from "../images/headerlogo.png";


const Footer = () => {

    return (
        <>
            <footer id="footer">
                <div class="footer-top">
                    <div class="container">
                        <div class="row">
                            <div className="col-md-4">
                                <div className="footer-content">
                                    <div className="footer-head">
                                        <div className="footer-logo">
                                            {/* <h2><span>e</span>Dge</h2> */}
                                            <img src={footerlogo} className="imgfooterlogo" alt='' />
                                        </div>

                                        <p>Sport-Specific Training | Strength & Conditioning | Fat Loss Training | Personal Training | Pre- & Post-Pregnancy Training | Nutrition Consultation</p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                            </div>

                            <div className="col-md-4">
                                <div className="footer-content">
                                    <div className="footer-head">
                                        <h4>Contact </h4>
                                        <div className="footer-contacts">
                                            <p><span class="bi bi-envelope"></span>
                                                <span className="footerpointes">info@urbanfitedge.com</span>
                                            </p>
                                            <p><span class="bi bi-phone"></span>
                                                <span className="footerpointes" > 9226089711 </span>
                                            </p>
                                            <p><span class="bi bi-house"></span>
                                                <span className="footerpointes" >   Urbanfit Edge, Opposite Lodha Bella Vita, Mohammad Wadi Rd, behind RPUG Badminton Turf, Mohammed Wadi, Pune, Maharashtra 411060
                                                </span>
                                            </p>
                                        </div>
                                        <div className="social-links mt-3">
                                            {/* <Link href="#" class="twitter"><i class="bx bxl-twitter"></i></Link> */}
                                            {/* <Link href="#" class="facebook"><i class="bx bxl-facebook"></i></Link> */}
                                            <Link href="#" className="instagram"><i className="bx bxl-instagram"></i></Link>
                                            {/* <Link href="#" class="google-plus"><i class="bx bxl-skype"></i></Link> */}
                                            <Link href="#" className="linkedin"><i className="bx bxl-linkedin"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="container top-border">
                    <div className="copyright">
                         <strong><span>Urbanfit Wellness Pvt Ltd</span></strong>. &copy; 2024 All Rights Reserved
                    </div>
                    
                    <div className="credits"> Design and developed by <Link target="_blank" to="https://www.nurasoftech.com/Best_Web_Development_Company_in_Pune.php">Nura Softech Pvt Ltd.</Link>
                    </div>
                </div>
                </div>

              
            </footer>
            {/* <a href="#" onClick={scrollToTop} className="back-to-top d-flex align-items-center justify-content-center "><i className="bi bi-arrow-up-short"></i></a> */}
        </>
    )
}
export default Footer;