import Navbar from '../Componants/Navbar';
import Footer from '../Componants/Footer';
const Help = () => {
    return (
        <>
        <Navbar/>
            <main id="main">
                <section className="breadcrumbs">
                    <div className="container">

                        <div className="d-flex justify-content-between align-items-center">
                            {/* <h2>Inner Page</h2>
                            <ol>
                                <li><a href="index.html">Home</a></li>
                                <li>Inner Page</li>
                            </ol> */}
                        </div>

                    </div>
                </section>
                <section className="inner-page">
                    <div className="container">
                        <h1>Help</h1>
                    </div>
                </section>
            </main>
            <Footer/>
        </>
    )
}
export default Help;