import React from "react";

export const Faq = () => {
  return (
    <section id="faq" class="faq section-bg d-none d-md-block d-lg-block">
      <div class="container">

        <div class="section-title">
          <h2>FAQ</h2>
        </div>

        <div class="faq-list">
          <ul>
            {/* faq 1 */}
            <li data-aos="fade-up">
              <a data-bs-toggle="collapse" class="collapse" data-bs-target="#faq-list-1">
                What is Edge?
                <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i>
              </a>
              <div id="faq-list-1" class="collapse show" data-bs-parent=".faq-list">
                <p>
                  The Edge is a cutting-edge athlete development center that seamlessly blends speed and
                  agility training, track workouts, specialized game-specific training, personalized one-on-one
                  coaching, dynamic strength training, and high-intensity plyometrics. In addition, we provide
                  specialized cardiovascular group activities that focus on injury prevention and rehabilitation.
                  Biomechanic video analysis by our professional trainers and health analysis by our nutritionists
                  for overall body weight and variable intensity training. Get to Edge for a personalized fitness
                  analysis and multiskill strength and conditioning program.
                </p>
              </div>
            </li>
            {/* faq 2 */}
            <li data-aos="fade-up" data-aos-delay="100">
              <a data-bs-toggle="collapse" data-bs-target="#faq-list-2" class="collapsed">
                What services can you avail of at Edge?  <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
              <div id="faq-list-2" class="collapse" data-bs-parent=".faq-list">
                <p>
                  Edge offers a variety of services, including:
                  <ul>
                    <li class="list1">Sport Specific Training</li>
                    <li class="list1">Strength and Conditioning</li>
                    <li class="list1">Guaranteed Fat Loss</li>
                    <li class="list1">Injury Rehab</li>
                    <li class="list1">Personal Training</li>
                    <li class="list1">Senior & kids Batches</li>
                    <li class="list1">Pre- & Post-Pregnancy Experts</li>
                  </ul>
                </p>
              </div>
            </li>
            {/* faq 3*/}
            <li data-aos="fade-up" data-aos-delay="200">
              <a data-bs-toggle="collapse" data-bs-target="#faq-list-3" class="collapsed">
                What age groups can benefit from athletic development?  <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
              <div id="faq-list-3" class="collapse" data-bs-parent=".faq-list">
                <p>
                  Athletes of all ages can benefit from athletic development programs.
                  However, it is especially important for young athletes to start training early to
                  develop a solid foundation of skills and fitness. Edge offers programs for athletes of all ages,
                  from beginners to elite competitors.
                </p>
              </div>
            </li>
            {/* faq 4 */}
            <li data-aos="fade-up" data-aos-delay="300">
              <a data-bs-toggle="collapse" data-bs-target="#faq-list-4" class="collapsed">
                What are the different types of athletic development programs at Edge?
                <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
              <div id="faq-list-4" class="collapse" data-bs-parent=".faq-list">
                <p>
                  Edge offers a variety of athletic development programs to meet the needs of athletes of all ages and skill levels. Our programs include:

                  <ul>
                    <li class="list1"> Junior Edge (ages 6-12)</li>
                    <li class="list1">Youth Edge (ages 13-18)</li>
                    <li class="list1"> Elite Edge (collegiate and professional athletes)</li>
                    <li class="list1">Sport-specific training programs (basketball, baseball, football, soccer, track and field, badminton, etc.)</li>
                    <li class="list1"> Goal-based one-on-one training</li>
                  </ul>
                </p>
              </div>
            </li>
            {/* faq 5 */}
            <li data-aos="fade-up" data-aos-delay="400">
              <a data-bs-toggle="collapse" data-bs-target="#faq-list-5" class="collapsed">
                What are the charges?  <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
              <div id="faq-list-5" class="collapse" data-bs-parent=".faq-list">
                <p>
                  The cost of Edge's athletic development programs varies depending on the type of program and the number of sessions per month. Please contact Edge for more information on pricing.<br />
                  Edge is more than just an athletic development center. It's a community of athletes and fitness enthusiasts who are all committed to reaching their full potential. No matter the age or skill level, our carefully crafted programs are tailored to assist athletes in realizing their ambitions, be it the beginning of their journey or striving for extraordinary accomplishments.<br />
                  At Edge, we believe that everyone has the potential to be great. We provide the tools and support athletes need to succeed, including:<br />
                  <ul>
                    <li class="list1"> Expert coaching</li>
                    <li class="list1">Comprehensive programs that combine all aspects of athletic development</li>
                    <li class="list1"> A state-of-the-art facility </li>
                    <li class="list1"> A supportive community</li>
                  </ul>
                </p>
              </div>
            </li>

          </ul>
        </div>

      </div>
    </section>
  );
};
export default Faq;