
import { Routes, Route } from 'react-router-dom';
import Home from './Componants/Home';
import About from './Pages/About';
import Help from './Pages/Help';
import Gallary from './Pages/Gallary';
import Contactus from './Pages/Contactus';
import Blog from './Pages/Blog';
import DnaTestContent from './DnaTestPage/DnaTestContent';
import Enquiryform from './Pages/EnquiryForm';
import Services from './Pages/Services';
import BlogDetails from './Pages/BlogDetails';
import StrengthArea from './Pages/Strength-AreaGallery';
import FunctinalTraining from './Pages/Functional-TrainingGallery';
import RunningArea from './Pages/Running-AreaGallery';
import PersonalTraining from './Pages/Personal-AreaGallery';

import './App.css';
import '../src/Componants/testimonial.css';

function App() {
 
  return (
    <>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/help' element={<Help />} />
        <Route path='/Gallery' element={<Gallary />} />
        <Route path='/contactus' element={<Contactus />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/blogdetail/:blogid/:MetaTitle' element={<BlogDetails />} />
        <Route path='/DNA-Test' element={<DnaTestContent />} />
        <Route path='/Services' element={<Services/>}/>
        <Route path='/Enquiry-Form' element={<Enquiryform/>}/>
        
        {/* Gallery */}
        <Route path='/Gallery/Strength-Area' element={<StrengthArea/>}/>
        <Route path='/Gallery/Functional-Training' element={<FunctinalTraining/>}/>
        <Route path='/Gallery/Running-Area' element={<RunningArea/>}/>
        <Route path='/Gallery/Personal-Training' element={<PersonalTraining/>}/>
        
      </Routes>
    </>
  );
}

export default App;
