import Navbar from '../Componants/Navbar';
import Footer from '../Componants/Footer';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../Pages/Blog.css';
import { NavLink } from 'react-router-dom';

const BlogDetails = () => {
  const { blogid } = useParams();
  const [image, setDataimage] = useState(null);
  const [blogtitle, setDatablogtitle] = useState(null);
  const [blogdesc, setDatadesc] = useState(null);
  const [data, setData] = useState(null);

  
  const [loading, setloading] = useState(true);

  useEffect(() => {
    function fetchData() {
      const apiUrl = "https://urbanfitedge.com/EdgeApi/blogview.php";

      const params =
      {
        blogid: blogid,
      };
      axios.get(apiUrl, { params })
        .then(response => {
          const blogData = response.data;
          const Status = blogData.Status;
          if (Status === 1) {
            const blogdetailData = blogData.Blog;
            var contentImage = "http://urbanfitapp.in/UAdminPanel/assets/blog_title_images/" + blogdetailData[0].BlogImage;
            setDataimage(contentImage);
            setDatablogtitle(blogdetailData[0].Title)
            setDatadesc(blogdetailData[0].Descr)

            const blogdetailreletedData = blogData.releatedblog;
            setData(blogdetailreletedData);
            setloading(false);

            document.title = blogdetailData[0].MetaTitle;
            const metaDescriptionTag = document.querySelector('meta[name="description"]');
            if (metaDescriptionTag) {
              metaDescriptionTag.content = blogdetailData[0].MetaDescription;
            }
            const metaKeywordsTag = document.querySelector('meta[name="keywords"]');
            if (metaKeywordsTag) {
              metaKeywordsTag.content = blogdetailData[0].MetaKeywords;
            }

          }
          else {
            console.error(blogData.Message);
          }

        })
        .catch(error => {
          console.error(error);
        });

    }

    fetchData();
  }, [blogid]);



  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth', // Use smooth scrolling
    });
};

  if(loading)
  {
    return(
      <div className="loading">
          <div className="sound-wave" >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
    )
  }




  return (
    <>
      <Navbar />
      <main id="main">
        <section className="inner-page">
          <div className="container">
            <div className='row mt-5'>
              <h1 className='codunderh1'>{blogtitle}</h1>
              <div className='container text-center'>
                <img src={image} className='strenghtimg' alt="detailimage" />
              </div>
            </div>
          </div>

          <div className="container founderssection">
            <div className='blogdesc mt-5' dangerouslySetInnerHTML={{ __html: blogdesc }} />
            <h1 className='similartag'>Similar Blogs</h1>
            <div className='row text-center reletedblog'>
            {data && data.map((item, index) => {
                const image = item.BlogImage;
                const blogId = item.id;
                const MetaTitle1 = item.MetaTitle;
                const urlEncodedString = MetaTitle1.replace(/ /g, "_");
                const blogdetailpageurl = `/blogdetail/${blogId}/${urlEncodedString}`;
                return (
                    <div className="col-md-4">
                      <NavLink to={blogdetailpageurl} onClick={scrollToTop}>
                      <img src={`http://urbanfitapp.in/UAdminPanel/assets/blog_title_images/${image}`} className=' swiperimg2 mt-4' alt='smillarblog'/>  
                      </NavLink>    
                    </div>
                  )
                })}
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  )

}

export default BlogDetails;