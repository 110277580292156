import Navbar from '../Componants/Navbar';
import Footer from '../Componants/Footer';

// import image4 from '../images/Services/02.webp';
import image4 from '../images/Services/service 4_4_11zon.webp';
import image8 from '../images/Services/service 8_8_11zon.webp';
import image1 from '../images/Services/service 1_1_11zon.webp';
import image2 from '../images/Services/service 2_2_11zon.webp';
import image3 from '../images/Services/service 3_3_11zon.webp';
import image5 from '../images/Services/service 5_5_11zon.webp';
import image6 from '../images/Services/service 6_6_11zon.webp';
import image7 from '../images/Services/service 7_7_11zon.webp';

import '../Pages/About.css';
import '../Pages/services.css';

const Services = () => {
    return (
        <>
            <Navbar />
            <main id="main">
                <section className="inner-page">
                    <div className="container">
                        <div className='row mt-5'>
                            <div className="col-md-8 col-sm-6 col-xs-12">
                                <div className="well-left">
                                    <div className="single-well">
                                        <img src={image1} alt="Contaentdetail4" className="contentsmallimages" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <div className="well-middle">
                                    <div className="single-well">
                                        <h4 className="sec-head d-flex">
                                            <span className="fitnessdetailnuber">01</span>  Sports-Specific Training :
                                        </h4>

                                        <p>
                                            We at Urbanfit Edge understand that sports enthusiasts and athletes have different training
                                            requirements. Our carefully crafted sport-specific training plans are intended to support
                                            people in becoming the best at the sports they choose. Our specialized exercises are designed
                                            to help you develop the unique abilities, strength, and conditioning needed for your sport,
                                            regardless of your experience level. With the help of our knowledgeable instructors, you'll
                                            develop and improve your skills for success on the court or field. Our dedication to your
                                            sports growth involves thorough evaluations, customized workout regimens, and continuous
                                            assistance.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-5 servicesrevert'>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="well-middle">
                                    <div className="single-well">

                                        <h4 className="sec-head d-flex">
                                            <span className="fitnessdetailnuber">02</span>  Strength & Conditioning::
                                        </h4>

                                        <p>
                                            Our programs for strength and conditioning are carefully designed to increase general physical conditioning, increase endurance, and develop functional strength. We serve everyone, from beginners to experienced exercise aficionados, regardless of their level of fitness. To ensure consistent growth and visible outcomes, our programs can be customized according to your unique requirements. Our professional trainers are available to assist you on your journey to strength and vitality, whether your goals are to increase your athletics, get healthier overall, or get ready for an upcoming competition. Our range of training techniques, which includes high-intensity interval training (HIIT) and conventional weightlifting, guarantees that your exercises are interesting and varied in addition to being efficient.
                                        </p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="well-left">
                                    <div className="single-well">
                                        <img src={image2} alt="Contaentdetail4" className="contentsmallimages" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mt-5'>
                            <div className="col-md-8 col-sm-6 col-xs-12">
                                <div className="well-left">
                                    <div className="single-well">
                                        <img src={image3} alt="Contaentdetail4" className="contentsmallimages" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <div className="well-middle">
                                    <div className="single-well">

                                        <h4 className="sec-head d-flex">
                                            <span className="fitnessdetailnuber">03</span>  Fat Loss Training:
                                        </h4>

                                        <p>
                                            Our fat reduction workout programs offer an extensive plan for successfully and persistently losing extra weight. We provide you with the resources and encouragement you need to meet your weight loss objectives by combining resistance training, cardiovascular activity, and a thorough nutritional counseling program. Our goal is to support you in creating a healthier, more balanced lifestyle in addition to helping you lose weight. Together, you and our committed staff of nutritionists and trainers will develop an individual regimen that takes into account your dietary choices, medical conditions, and your goals.We recognize that losing weight is a complex process, and our programs take responsibility for not just the physical but also the psychological and emotional aspects, making sure you not only have the physique you want but also a long-lasting sense of wellbeing.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-5 servicesrevert'>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="well-middle">
                                    <div className="single-well">

                                        <h4 className="sec-head d-flex">
                                            <span className="fitnessdetailnuber">04</span>  Personal Training:
                                        </h4>

                                        <p>
                                            Our personal training sessions offer you the benefits of a dedicated fitness expert who customizes workouts specifically to your goals and preferences. Whether your focus is on weight management, muscle gain, or overall fitness improvement, our trainers become your partners in success, providing expert guidance and motivation. The personalization of our training sessions ensures that every workout is tailored to your specific needs, making your fitness journey efficient and enjoyable. Your goals become our goals, and together, we embark on a path to a healthier, happier you. Our trainers are not just fitness experts but also life coaches, offering support, motivation, and accountability to keep you on track. From tracking your progress to adjusting your workouts as you improve, our trainers are with you every step of the way.
                                        </p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="well-left">
                                    <div className="single-well">
                                        <img src={image4} alt="Contaentdetail4" className="contentsmallimages" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-5'>
                            <div className="col-md-8 col-sm-6 col-xs-12">
                                <div className="well-left">
                                    <div className="single-well">
                                        <img src={image5} alt="Contaentdetail4" className="contentsmallimages" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <div className="well-middle">
                                    <div className="single-well">

                                        <h4 className="sec-head d-flex">
                                            <span className="fitnessdetailnuber">05</span>  Pre- & Post Pregnancy :

                                        </h4>

                                        <p>
                                            Pregnancy is a transformative journey, and our pre & post pregnancy programs ensure that you maintain your health and fitness throughout and after pregnancy. These programs provide gentle yet effective workouts and comprehensive nutrition support, making your journey smooth, healthy, and joyous. Whether you're an expectant mother or a new mom, our programs are tailored to cater to your unique needs, ensuring your well-being is our top priority. We understand the physical and emotional challenges that pregnancy and motherhood bring, and our programs provide not just fitness guidance but also a supportive community of mothers who share your journey. Our experienced trainers and nutritionists work together to create a holistic plan that ensures a healthy pregnancy, a smooth post-pregnancy recovery, and a seamless transition into motherhood.

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-5 servicesrevert'>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="well-middle">
                                    <div className="single-well">

                                        <h4 className="sec-head d-flex">
                                            <span className="fitnessdetailnuber">06</span>  Senior & kids Batches:
                                        </h4>

                                        <p>
                                            At Urbanfit Edge, we believe in the inclusivity of fitness extends to every age group, which is why we offer specialized training batches for seniors and kids. These programs are designed to cater to the unique requirements of different age groups, focusing on safety, health, and fun. Our senior batches offer age-appropriate workouts to maintain mobility and wellness in a supportive environment. We understand the unique needs and concerns of seniors and provide a safe, welcoming, and enjoyable fitness environment. For kids, our programs make fitness enjoyable and help instill healthy habits from an early age. We offer fun and engaging workouts that focus on coordination, agility, and overall health, ensuring that fitness becomes an integral part of a child's life. Our commitment to ensuring that fitness is a joyful experience for everyone, regardless of age.
                                        </p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="well-left">
                                    <div className="single-well">
                                        <img src={image6} alt="Contaentdetail4" className="contentsmallimages" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-5'>
                            <div className="col-md-8 col-sm-6 col-xs-12">
                                <div className="well-left">
                                    <div className="single-well">
                                        <img src={image7} alt="Contaentdetail4" className="contentsmallimages" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <div className="well-middle">
                                    <div className="single-well">

                                        <h4 className="sec-head d-flex">
                                            <span className="fitnessdetailnuber">07</span>  Injury Rehab:
                                        </h4>

                                        <p>
                                            Recovering from an injury can be a challenging path, but our injury rehab programs are designed to help you regain strength, flexibility, and mobility safely and efficiently. Our trainers are well-versed in injury management and recovery, ensuring your path to wellness is guided by experts who understand the complexity of rehabilitation. We understand that every injury is unique, and our programs are customized to address your specific needs, whether you're recovering from a sports-related injury, surgery, or any other physical setback. Our approach to injury rehab includes a combination of manual therapy, corrective exercises, and gradual progression, ensuring that your recovery is not just swift but also safe. Our experienced team collaborates with healthcare professionals to provide a comprehensive and integrated approach to rehabilitation, ensuring that you regain your strength and mobility with the utmost care and attention.

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-5 servicesrevert'>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="well-middle">
                                    <div className="single-well">
                                        <h4 className="sec-head d-flex">
                                            <span className="fitnessdetailnuber">08</span>  Nutrition Counseling:
                                        </h4>

                                        <p>
                                            Nutrition is the cornerstone of well-being. Achieving your fitness goals is not just about exercise; it's also about nourishing your body with the right fuel. Our nutrition counseling services provide you with tailored dietary guidance to complement your training efforts and optimize your overall health. Whether you're looking to manage your weight, improve your athletic performance, or simply lead a healthier lifestyle, our expert nutritionists are here to help you make informed and sustainable choices in your diet. We believe that nutrition is not a one-size-fits-all solution, and our approach to nutrition counseling is highly personalized. We take into account your dietary preferences, cultural considerations, and any dietary restrictions or allergies, ensuring that your nutrition plan is not only effective but also enjoyable.We understand that nutrition is a lifelong journey, and we're here to guide you on
                                        </p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="well-left">
                                    <div className="single-well">
                                        <img src={image8} alt="Contaentdetail4" className="contentsmallimages" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />


        </>
    )
}
export default Services;