
import '../Componants/Enquiryformcss.css'
import image5 from '../images/banner5.webp';
import React, { useState, useEffect } from 'react'


function Veryfiotp() {

    const [formData, setFormData] = useState({
        number: '', otp: ''
    });

    const [formData2, setForm2Data] = useState({
        name: '', email: '', weight: '', height: '', inquiry: '', dob: '', gender: '', unumber: ''
    });

    const handleClearInputs = () => {
        setForm2Data({ name: '', email: '', weight: '', height: '', inquiry: '', dob: '', gender: '', unumber: ''}); 
    };

    useEffect(() => {
        setForm2Data({
            ...formData2,
            unumber: `${formData.number}`
        });
    }, [formData.number]);


    const [isHidden, setIsHidden] = useState(true);
    const [isDiv2Hidden, setIsDiv2Hidden] = useState(true);
    const [isDiv3Hidden, setIsDiv3Hidden] = useState(true);

    const [isinputHidden, setIsinputHidden] = useState(true);
    const [issentotpHidden, setIssentotpHidden] = useState(false);
    const [issubmitotpHidden, setIssubmitotpHidden] = useState(true);
    const [issubmitHidden, setIssubmitHidden] = useState(true);
    const [isHiddenfullform, setIsHiddenfullform] = useState(true);
    const [ischangenumHidden, setIschangenumHidden] = useState(true);

    const [isDisabled, setIsDisabled] = useState(false);
     
    // Check User Entery
    const handleSubmit = async (e) => {
        e.preventDefault();

        // // Check if the 'name' field is empty
        // if (formData.number.trim().length !== 10) {
        //     alert('Enter Valid Number');
        //     return;
        // }

        setIsHidden(!isHidden);

        try {
            fetch('https://urbanfitedge.com/EdgeApi/checkuser.php',
                {
                    method: 'POST',
                    headers: { "Content-Type": "multipart/form-data" },
                    body: JSON.stringify(formData)
                }).then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    } else {

                        return response.json();
                    }
                }).then(data => {

                    if (data.is_user === 1) {
                        setIsHidden(isHidden);
                        setIsDiv2Hidden(!isDiv2Hidden);
                        setTimeout(() => {
                            setIsDiv2Hidden(isDiv2Hidden);
                        }, 2000);

                    } else {

                        setIssubmitotpHidden(!issubmitotpHidden);
                        setIsHidden(isHidden);
                        setIssentotpHidden(!issentotpHidden);
                        setIsinputHidden(!isinputHidden);
                        setIssubmitHidden(!issubmitHidden);
                        setIsDisabled(true);
                        setIschangenumHidden(!ischangenumHidden);
                    }
                })
        } catch (error) {
            console.error('Error:', error);
        }
    };

    //Submit otp 
    const handleSubmittop = async (e) => {
        e.preventDefault();
        // Check if the 'name' field is empty
        if (formData.otp.trim() === '') {
            alert('Enter Valid OTP');
            return;
        }

        setIsHidden(!isHidden);
        try {
            fetch('https://urbanfitedge.com/EdgeApi/validuserotp.php',
                {
                    method: 'POST',
                    headers: { "Content-Type": "multipart/form-data" },
                    body: JSON.stringify(formData)
                }).then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    } else {

                        return response.json();
                    }
                }).then(data => {
                
                    if (data.validotp === 1) {
                        setIsinputHidden(!isinputHidden);
                        setIssubmitotpHidden(!issubmitotpHidden);
                        setIsHidden(isHidden);
                        setIsHiddenfullform(!isHiddenfullform)
                        setIschangenumHidden(!ischangenumHidden);


                    } else {
                        setIsHidden(isHidden);
                        setIsDiv3Hidden(isDiv3Hidden);
                        alert("Enter Valid OTP");
                    }
                })
        } catch (error) {
            console.error('Error:', error);
        }
    };

    function isValidEmail(email) {
        // Regular expression pattern for a valid email address
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(email);
    }

    // Add User Details
    const handleformSubmit = async (e) => {
        e.preventDefault();

        // Example usage:
        if (!isValidEmail(formData2.email)) {
            alert('Enter a valid email address');
            return;
        }

        // Name valid
        if (formData2.name.trim() === '') {
            alert('Enter Valid Name');
            return;
        }

        // DOB valid
        if (formData2.dob.trim() === '') {
            alert('Enter Valid Date of Birth');
            return;
        }

        setIsHidden(!isHidden);
        try {
            fetch('https://urbanfitedge.com/EdgeApi/addcustomer.php',
                {
                    method: 'POST',
                    body: JSON.stringify(formData2)
                }).then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    } else {

                        return response.json();
                    }
                }).then(data => {
                 
                    if (data.insert === 1) {  

                        setIsinputHidden(!isinputHidden);
                        setIssubmitotpHidden(!issubmitotpHidden)
                        setIsDisabled(false);
                        setIschangenumHidden(!ischangenumHidden);
                        setIssentotpHidden(!issentotpHidden);
                        handleClearInputs();

                    } else {    
                        setIsinputHidden(!isinputHidden);
                        setIssubmitotpHidden(!issubmitotpHidden)
                        setIsDisabled(false);
                        setIschangenumHidden(!ischangenumHidden);
                        setIssentotpHidden(!issentotpHidden);
                        handleClearInputs();
                    }
                })
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "number" ) {
            const inputnum = value.replace(/[^0-9.]/g, '').substring(0, 10);
            setFormData({ ...formData2, [name]: inputnum });
        }
        else
        {
            setFormData({ ...formData, [name]: value });
        }
 
    };
    const handleInputChange2 = (e) => {

        const { name, value } = e.target;

        // Validation for the "weight" field
        if (name === "weight" || name === "height") {
            const inputValue = value.replace(/[^0-9.]/g, '').substring(0, 10);
            setForm2Data({ ...formData2, [name]: inputValue });
        }else{
            setForm2Data({ ...formData2, [name]: value });
        }

    };

    const changNumberhandle = () => {
        setIsinputHidden(!isinputHidden);
        setIssubmitotpHidden(!issubmitotpHidden)
        setIsDisabled(false);
        setIschangenumHidden(!ischangenumHidden);
        setIssentotpHidden(!issentotpHidden);
        handleClearInputs();
    };

    return (
        <>
            <div className="full-screen-container">
                <img src={image5} className="backgournimg" alt="..." />
                <div className="centered">GO</div>
                <h3 className="login-title">Welcome</h3>
                <div className='container'>
                    <form className='enteroptform' method='post'>
                        <div className="input-group">
                            <label className='animate__animated animate__fadeInDown'>Mobile No.</label>
                            <input type="text" className='animate__animated animate__fadeInDown usermobileno' name="number" id="number" onChange={handleInputChange} disabled={isDisabled} value={formData.number} />
                        </div>
                        <div className="input-group changnumdiv" style={{ display: ischangenumHidden ? 'none' : 'block' }}>
                            <button type="button" className='changenumber' onClick={changNumberhandle} >
                                Change Number
                            </button>
                        </div>
                        <div className="input-group">
                            <button type="submit" className="login-button" onClick={handleSubmit} style={{ display: issentotpHidden ? 'none' : 'block' }} >Sent OTP</button>
                        </div>

                        <div style={{ display: isinputHidden ? 'none' : 'block' }}>
                            <div className="input-group" >
                                <label className='animate__animated animate__fadeInDown'>Enter OTP.</label>
                                <input type="number" className='animate__animated animate__fadeInDown' name="otp" id="otp" onChange={handleInputChange} value={formData.otp} />
                            </div>
                        </div>
                        <div className="input-group">
                            <button type="submit" className="login-button" onClick={handleSubmittop} style={{ display: issubmitotpHidden ? 'none' : 'block' }} >Submit Otp</button>
                        </div>
                    </form>
                    <div style={{ display: isHiddenfullform ? 'none' : 'block' }} >
                        <form className='enteroptform' onSubmit={handleformSubmit} method="post" >
                            {/* Form field start */}
                            <div className="input-group">
                                <label className='animate__animated animate__fadeInDown'>Name</label>
                                <input type="text" className='animate__animated animate__fadeInDown' name="name" id="name" onChange={handleInputChange2} value={formData2.name} />

                                <input type="hidden" className='animate__animated animate__fadeInDown' name="unumber" id="unumber" onChange={handleInputChange2} value={formData2.unumber} />
                            </div>

                            <div className="input-group">
                                <label className='animate__animated animate__fadeInDown'>Email</label>
                                <input type="email" className='animate__animated animate__fadeInDown' name="email" id="email" onChange={handleInputChange2} value={formData2.email} />
                            </div>
                            <div className="input-group">
                                <label className='animate__animated animate__fadeInDown'>Weight (Kg)</label>
                                <input type="text" className='animate__animated animate__fadeInDown' name="weight" id="weight" onChange={handleInputChange2} value={formData2.weight} />
                            </div>
                            <div className="input-group">
                                <label className='animate__animated animate__fadeInDown'>Height (Ft)</label>
                                <input type="text" className='animate__animated animate__fadeInDown' name="height" id="height" onChange={handleInputChange2} value={formData2.height} />
                            </div>
                            <div className="input-group">
                                <label className='animate__animated animate__fadeInDown'>Inquiry From</label>
                                <input type="text" className='animate__animated animate__fadeInDown' name="inquiry" id="inquiry" onChange={handleInputChange2} value={formData2.inquiry} />
                            </div>
                            <div className="input-group">
                                <label className='animate__animated animate__fadeInDown'>Date Of Birth</label>
                                <input type="date" className='animate__animated animate__fadeInDown' name="dob" id="dob" onChange={handleInputChange2} value={formData2.dob} />
                            </div>
                            <div className="input-group">
                                <label className='animate__animated animate__fadeInDown'>Gender </label>
                                <select value={formData2.gender} onChange={handleInputChange2} name="gender" id="gender" required >
                                    <option value="">--Select--</option>
                                    <option value="M">Male</option>
                                    <option value="F">Female</option>
                                </select>
                            </div>
                            <div className="input-group">
                                <button type="submit" className="login-button submtibtn" >Submit</button>
                            </div>
                            {/* <div className="input-group">
                                <label className='animate__animated animate__fadeInDown'>Reminder Date</label>
                                <input type="date" className='animate__animated animate__fadeInDown' name="number" id="number" onChange={handleInputChange}  value={formData.number} />
                            </div> */}

                            {/* End form fields */}

                        </form>
                    </div>
                    <div className="my-3">
                        <div className="container" style={{ display: isHidden ? 'none' : 'block' }}>
                            <div className="sound-wave" >
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div className="error-message errormsg" style={{ display: isDiv2Hidden ? 'none' : 'block' }} > User Already Existed..!</div>
                        <div className="error-message errormsg" style={{ display: isDiv3Hidden ? 'none' : 'block' }} > Invalid OTP </div>
                    </div>

                </div>
                {/* <div className="container">
                <form>
                    <div className="row">
                        <div className="col">
                            <input type="text" className="form-control" id="email" placeholder="Enter email" name="email" />
                        </div>
                        <div className="col">
                            <input type="password" className="form-control" placeholder="Enter password" name="pswd"/>
                        </div>
                    </div>
                </form>
            </div> */}

            </div>
        </>
    );
}
export default Veryfiotp;
