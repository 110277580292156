import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import headerlogo from '../images/headerlogo.png';
import toggolelogo from '../images/toggleicon.png'


const Navbar = () => {

    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleShowModal = () => {
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Use smooth scrolling
        });
    };

    return (

        <>
            <header id="header" className="fixed-top d-flex align-items-center">
                <div className="container-fluid d-flex align-items-center justify-content-between" style={{ width: '95%' }}>

                    {/* <h1 className="logo"><a href="index.html">Groovin</Link></h1> */}
                    <Link to="/" className="logo">
                        <img src={headerlogo} alt="" className="img-fluid" onClick={scrollToTop} />
                    </Link>
                    <nav id="navbar" className="navbar">
                        <ul>
                            <li id="righttoggol" style={{ display: isModalVisible ? 'none' : 'block', cursor: 'pointer' }}  data-bs-toggle="modal"
                                    data-bs-target="#sidebarModal"
                                    onClick={handleShowModal}>
                                <img
                                    src={toggolelogo}
                                    alt="Logo"
                                    className="d-inline-block align-top right-logo toggolemenu" />
                            </li>
                        </ul>

                    </nav>
                </div>
            </header>

            <div className="modal  fade come-from-modal right" id="sidebarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ width: '100%' }}>
                <div className="modal-dialog  modal-sm">
                    <div className="modal-content">
                        <div className="modal-header" style={{ borderBottom: 'none' }}>
                            <button onClick={handleCloseModal} type="button" className="close-btn " data-bs-dismiss="modal" aria-label="Close" >X</button>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="koh-tab-content">
                                            <div className="koh-tab-content-body">
                                                <div id="sidebar-wrapper">
                                                    <ul class="sidebar-nav">
                                                        <li class="sidebar-brand" onClick={handleCloseModal} data-bs-dismiss="modal" >
                                                            <Link to="/" onClick={scrollToTop} >Home  </Link>
                                                        </li>
                                                        <hr className="menudevider"></hr>
                                                        <li class="sidebar-brand" onClick={handleCloseModal} data-bs-dismiss="modal" >
                                                            <Link to="/about" onClick={scrollToTop}  >About  </Link>
                                                        </li>
                                                        <hr className="menudevider"></hr>

                                                        <li class="sidebar-brand" onClick={handleCloseModal} data-bs-dismiss="modal" >
                                                            <Link to="/Gallery" onClick={scrollToTop} >Gallery  </Link>
                                                        </li>
                                                        <hr className="menudevider"></hr>
                                                        <li class="sidebar-brand" onClick={handleCloseModal} data-bs-dismiss="modal" >
                                                            <Link to="/DNA-Test" onClick={scrollToTop}  >DNA Test  </Link>
                                                        </li>
                                                        <hr className="menudevider"></hr>
                                                        <li class="sidebar-brand" onClick={handleCloseModal} data-bs-dismiss="modal" >
                                                            <Link to="/Services" onClick={scrollToTop}  >Services </Link>
                                                        </li>
                                                        <hr className="menudevider"></hr>
                                                        <li class="sidebar-brand" onClick={handleCloseModal} data-bs-dismiss="modal"  >
                                                            <Link to="/blog" onClick={scrollToTop} >Blog  </Link>
                                                        </li>
                                                        {/* <hr className="menudevider"></hr>
                                                        <li class="sidebar-brand" onClick={handleCloseModal} data-bs-dismiss="modal" >
                                                            <Link to="/help" onClick={scrollToTop} >Help  </Link>
                                                        </li> */}


                                                        <hr className="menudevider"></hr>

                                                        <li class="sidebar-brand" onClick={handleCloseModal} data-bs-dismiss="modal"  >
                                                            <Link to="/contactus" onClick={scrollToTop} >Contact us  </Link>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Navbar;