import Navbar from '../Componants/Navbar';
import Footer from '../Componants/Footer';
import React, {useState } from 'react'

const Contactus = () => {

    // Get the current URL
    const currentUrl = window.location.href;

    // Create a URLSearchParams object from the URL
    // const urlSearchParams = new URLSearchParams(currentUrl);

    const inputString = currentUrl;
    // Split the string into an array using a delimiter (in this case, a comma)
    const splitArray = inputString.split('?');
    var utmlinks = splitArray[1];

    var utmlink = "";

    if (utmlinks) {
        utmlink = utmlinks;
    }

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        number: '',
        pagetype: 'Contacut Us Page',
        utm_link: utmlink
    });
    const [isHidden, setIsHidden] = useState(true);
    const [isDiv2Hidden, setIsDiv2Hidden] = useState(true);

    const handleClearInputs = () => {
        setFormData({ name: '', number: '', email: '' });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsHidden(!isHidden);

        if (!isValidEmail(formData.email)) {
            alert('Enter a valid email address');
            return;
        }

        try {
            const response = await fetch('https://urbanfitedge.com/EdgeApi/contactus.php',
                {
                    method: 'POST',
                    body: JSON.stringify(formData),
                });
            if (response.ok) 
            {
                handleClearInputs();
                setIsHidden(isHidden);
                setIsDiv2Hidden(!isDiv2Hidden);

                setTimeout(() => {
                    setIsDiv2Hidden(isDiv2Hidden);
                }, 2000);

            } else {
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    function isValidEmail(email) {
        // Regular expression pattern for a valid email address
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(email);
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        // setFormData({ ...formData, [name]: value });

        if (name === "number") {
            const inputnum = value.replace(/[^0-9.]/g, '').substring(0, 10);
            setFormData({ ...formData, [name]: inputnum });
        }
        else {
            setFormData({ ...formData, [name]: value });
        }

    };
    
    return (
        <>
            <Navbar />
            <body className="page">
                <main className="main page__main">
                    <div className="container">
                        <div className="row justify-content-center mt-5">
                            <div className="col-md-8">
                                <p className="contactuspage">BOOK FREE TRIAL</p>
                                <p className='contacuspagedetails'>Book Free 1-month Trail Session</p>
                                <form>
                                    <div className="form-group mt-5">  
                                        <input type="text" className="form-control customeinput main-form" name="name" id="exampleInputEmail1" value={formData.name} onChange={handleInputChange} aria-describedby="emailHelp" placeholder='Name' />
                                    </div>
                                    <div className="form-group mt-5">
                                        <input type="text" className="form-control customeinput main-form" name="number" id="exampleInputPassword1" value={formData.number} onChange={handleInputChange} placeholder='Number' />
                                    </div>
                                    <div className="form-group mt-5">
                                        <input type="email" className="form-control customeinput main-form" name="email" id="exampleInputPassword1" placeholder='Email' value={formData.email} onChange={handleInputChange} />
                                    </div><br />
                                    <center>
                                        <button type="button" className='submitcontactus' onClick={handleSubmit} >Submit</button>
                                    </center>
                                    <center className='mt-1'><h6>OR</h6></center>
                                    <center>
                                        <a href="tel:+919226089711">
                                            <button type="button" className='submitcontactus'>Call us</button>
                                        </a>
                                    </center>
                                    <div className="my-3">
                                        <div className="container" style={{ display: isHidden ? 'none' : 'block' }}>
                                            <div className="sound-wave" >
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </div>
                                        <div className="error-message"></div>
                                        <div className="sent-message" style={{ display: isDiv2Hidden ? 'none' : 'block' }} >Your message has been sent. Thank you!</div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </main>
            </body>
            <Footer />
        </>
    )
}
export default Contactus;