import Navbar from '../Componants/Navbar';
import Footer from '../Componants/Footer';
import '../Pages/Blog.css';

import image7 from '../images/Services/service 6_6_11zon.webp';
import image8 from '../images/Services/service 8_8_11zon.webp';
import image1 from '../images/Services/service 1_1_11zon.webp';
import image3 from '../images/Services/service 2_2_11zon.webp';
import image2 from '../images/Services/service 3_3_11zon.webp';
import image5 from '../images/Services/service 5_5_11zon.webp';
import image6 from '../images/Services/service 7_7_11zon.webp';
import image4 from '../images/Services/service 4_4_11zon.webp';


import rightdirection from '../images/Icon/rightdirection.png';

import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

const Blog = () => {
  const [loading, setloading] = useState(true);

  const [data, setData] = useState(null);
  const [letestblogimg, setletestblogimg] = useState(null);
  const [letestblogtitle, setletestblogtitle] = useState(null);
  const [letestblogid, setletestblogid] = useState(null);
  const [letestMetaTitle,setMetaTitle]=useState(null);

  useEffect(() => {

    function fetchData() {
      const apiUrl = "https://urbanfitedge.com/EdgeApi/getblog.php";
      axios.get(apiUrl, {})
        .then(response => {
          const blogData = response.data;
          const Status = blogData.Status;
          if (Status === 1) {
            const blogdetailData = blogData.Blog;
            setletestblogimg(blogdetailData[0].BlogImage);
            setletestblogtitle(blogdetailData[0].Title);
            setletestblogid(blogdetailData[0].id);
           const MetaTitle1 = blogdetailData[0].MetaTitle;
           const  urlEncodedStringletet = MetaTitle1.replace(/ /g, "_");
           setMetaTitle(urlEncodedStringletet);
           setData(blogdetailData);


            loadScripts();
            setloading(false);
          }
          else {
            console.error(blogData.Message);
          }

        })
        .catch(error => {
          console.error(error);
        });

    }

    fetchData();
  }, []);


  // Function to load scripts
  async function loadScripts() {
    try {
      await loadScript('./assets/custome_js/owl_Slider.js');
      await loadScript('./assets/custome_js/swiper_slider.js');
    } catch (error) {
      console.error('Error loading scripts:', error);
    }
  }
  // Function to load a script dynamically
  function loadScript(src) {
    return new Promise(function (resolve, reject) {
      var script = document.createElement('script');
      script.src = src;
      script.addEventListener('load', function () {
        resolve();
      });
      script.addEventListener('error', function (e) {
        reject(e);
      });
      document.body.appendChild(script);
    });
  }

  if (loading) {
    return (
      <div className="loading">
        <div className="sound-wave" >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    )
  }

  return (
    <>
      <Navbar />
      <main id="main">
        <section className="inner-page">
          <div className="contaier-fluid ">
            <div className="row testimonialheading">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="section-headline">
                  <h2 className="testimonialheader mt-5">Latest Blogs</h2>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
          <div className="owl-carousel owl-theme">
              {data && data.map((item, index) => {
                const image = item.BlogImage;
                const blogId = item.id;
                const MetaTitle1 = item.MetaTitle;
               
                const urlEncodedString = MetaTitle1.replace(/ /g, "_");
                console.log(urlEncodedString);
                const blogdetailpageurl = `/blogdetail/${blogId}/${urlEncodedString}`;
                return (
                  <NavLink to={blogdetailpageurl}>

                    <div className="owl-item">
                    <div className="card blogcard">
                      <img src={`http://urbanfitapp.in/UAdminPanel/assets/blog_title_images/${image}`} className="blogimg" />
                      </div>
                    </div>
                  </NavLink>
                )
              })}
            </div>
          </div>

          <div className="container founderssection">
            <h1 className='codunderh1'>Categories</h1>
            {/* Swiper Slider  Desktop view*/}
            <div className='row mt-5 d-none d-md-block d-lg-block'>
              <div className="swiper-container swipe1">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <img src={image1} className="swiperimg" alt="swiperimage" /><br />  <br />
                    <span className='detail_name'>Sport-Specific Training</span>
                  </div>
                  <div className="swiper-slide">
                    <img src={image2} className="swiperimg" alt="swiperimage" />
                    <br />  <br />
                    <span className='detail_name' >Strength & Conditioning</span>
                  </div>
                  <div className="swiper-slide">
                    <img src={image3} className="swiperimg" alt="swiperimage" />
                    <br />  <br />
                    <span className='detail_name' >Fat Loss</span>
                  </div>
                  <div className="swiper-slide">
                    <img src={image4} className="swiperimg" alt="swiperimage" />
                    <br />  <br />
                    <span className='detail_name' >Personal Training</span>
                  </div>
                  <div className="swiper-slide">
                    <img src={image5} className="swiperimg" alt="swiperimage" />
                    <br />  <br />
                    <span className='detail_name' >Pre- & Post-Pregnancy</span>
                  </div>
                  <div className="swiper-slide">
                    <img src={image6} className="swiperimg" alt="swiperimage" />
                    <br />  <br />
                    <span className='detail_name' >Senior & Kid Batches</span>
                  </div>
                  <div className="swiper-slide">
                    <img src={image7} className="swiperimg" alt="swiperimage" />
                    <br />  <br />
                    <span className='detail_name' >Injury Rehab</span>
                  </div>
                  <div className="swiper-slide">
                    <img src={image8} className="swiperimg" alt="swiperimage" />
                    <br />  <br />
                    <span className='detail_name' >Nutrition Counseling</span>
                  </div>
                </div>
                {/* <div className="swiper-pagination swiper-pagination1"></div> */}
                {/* <div className="swiper-button-prev swiper-button-prev1"></div>
              <div className="swiper-button-next swiper-button-next1"></div> */}


                <div className="swiper-scrollbar"></div>
              </div>
            </div>

            {/* mobile view */}
            <div className='row mt-5 d-lg-none'>

              <div className=" col-sm-6 col-xl-3 col-lg-3 col-md-6 ourcoacheimg " data-wow-delay="0.1s">
                <div className="member mt-3 text-center">
                  <img src={image1} className="img-fluid memberimg swiperimg" alt="" />
                  <br />  <br />
                  <span className='detail_name'>Sport-Specific Training</span>
                </div>
              </div>
              <div className=" col-sm-6 col-xl-3 col-lg-3 col-md-6 ourcoacheimg" data-wow-delay="0.1s">
                <div className="member mt-3 text-center">
                  <img src={image2} className="img-fluid memberimg swiperimg" alt="" />
                  <br />  <br />
                  <span className='detail_name' >Strength & Conditioning</span>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3 col-lg-3 col-md-6 ourcoacheimg " data-wow-delay="0.1s">
                <div className="member mt-3 text-center">
                  <img src={image3} className="img-fluid memberimg swiperimg" alt="" />
                  <br />  <br />
                  <span className='detail_name' >Fat Loss</span>
                </div>
              </div>
              <div className=" col-sm-6 col-xl-3 col-lg-3 col-md-6 ourcoacheimg " data-wow-delay="0.1s">
                <div className="member mt-3 text-center">
                  <img src={image4} className="img-fluid memberimg swiperimg" alt="" />
                  <br />  <br />
                  <span className='detail_name' >Personal Training</span>
                </div>
              </div>
              <div className=" col-sm-6 col-xl-3 col-lg-3 col-md-6 ourcoacheimg" data-wow-delay="0.1s">
                <div className="member mt-3 text-center">
                  <img src={image5} className="img-fluid memberimg swiperimg" alt="" />
                  <br />  <br />
                  <span className='detail_name' >Pre- & Post-Pregnancy</span>
                </div>
              </div>
              <div className=" col-sm-6 col-xl-3 col-lg-3 col-md-6 ourcoacheimg" data-wow-delay="0.1s">
                <div className="member mt-3 text-center">
                  <img src={image6} className="img-fluid memberimg swiperimg" alt="" />
                  <br />  <br />
                  <span className='detail_name' >Senior & Kid Batches</span>
                </div>
              </div>
              <div className=" col-sm-6 col-xl-3 col-lg-3 col-md-6 ourcoacheimg " data-wow-delay="0.1s">
                <div className="member mt-3 text-center">
                  <img src={image7} className="img-fluid memberimg swiperimg" alt="" />
                  <br />  <br />
                  <span className='detail_name' >Injury Rehab</span>
                </div>
              </div>
              <div className=" col-sm-6 col-xl-3 col-lg-3 col-md-6 ourcoacheimg " data-wow-delay="0.1s">
                <div className="member mt-3 text-center">
                  <img src={image8} className="img-fluid memberimg swiperimg" alt="" />
                  <br />  <br />
                  <span className='detail_name ' >Nutrition Counseling</span>
                </div>
              </div>

            </div>
            <div className='row mt-5'>
              <h1 className='codunderh1'>Strength & Conditioning</h1>

              <div className='container text-center'>

              <NavLink to={`/blogdetail/${letestblogid}/${letestMetaTitle}`}>

                 <img src={`http://urbanfitapp.in/UAdminPanel/assets/blog_title_images/${letestblogimg}`} className='strenghtimg' alt="detailimage" />
                </NavLink>
                <h1 className='strengthname'>{letestblogtitle}</h1>
                <NavLink to={`/blogdetail/${letestblogid}/${letestMetaTitle}`}>
                 <span className='readmore'>Read More <img src={rightdirection} className="readmore_arr" alt="" /></span>
                </NavLink>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}
export default Blog;