// import image1 from "../images/Ellipse 1.png"
import React, { useEffect, useState } from 'react';
export const Testimonoial = () => {

  useEffect(() => {
    loadScripts();
}, []);

// Function to load scripts
async function loadScripts()
{
  try {
    await loadScript('./assets/custome_js/owl_Slider.js');
  } catch (error) {
    console.error('Error loading scripts:', error);
  }
}
 // Function to load a script dynamically
 function loadScript(src)
 {
   return new Promise(function (resolve, reject) {
     var script = document.createElement('script');
     script.src = src;
     script.addEventListener('load', function () {
       resolve();
     });
     script.addEventListener('error', function (e) {
       reject(e);
     });
     document.body.appendChild(script);
   });
 }

  return (
    <>
      <section id="team" className="team">

      <div className="contaier-fluid ">
          <div className="row testimonialheading">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="section-headline text-center">
                <h2 className="testimonialheader mt-5">Testimonial</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="owl-carousel owl-theme ">
          <div className="owl-item">
            <div className="card">
              {/* <div className="img-card">
                <img src={image1}
                  alt="" />
              </div> */}
              <h2 className="testimonilaname">Tushar Sable</h2>
              <div className="testimonial mt-4 mb-2">
                I have known Kirat Sir for a very long time and have also taken
                training from him at his own center. However, at his center, I faced space issues.
                But at Edge, Kirat Sir has 10,000sq of space, which makes it much more comfortable to train.
                Additionally, Edge has a wide variety of equipment, which allows Kirat Sir to design more
                comprehensive and effective training programs.
              </div>
              <div id="full-stars-example">
                <div className="rating-group">
                  <label aria-label="1 star" className="rating__label" for="rating-1"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-1" value="1" type="radio" />
                  <label aria-label="2 stars" className="rating__label" for="rating-2"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-2" value="2" type="radio" />
                  <label aria-label="3 stars" className="rating__label" for="rating-3"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-3" value="3" type="radio" checked />
                  <label aria-label="4 stars" className="rating__label" for="rating-4"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-4" value="4" type="radio" />
                  <label aria-label="5 stars" className="rating__label" for="rating-5"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-5" value="5" type="radio" />
                </div>
              </div>
            </div>
          </div>
          <div className="owl-item mt-5">
            <div className="card">
              {/* <div className="img-card">
                <img src={image1} alt="" />
              </div> */}
              <h2 className="testimonilaname">Mansi Madekar</h2>
              <div className="testimonial mt-4 mb-2">
                As a senior citizen, I was worried that I was too old to join a fitness center. However,
                Edge Fitness Center has completely changed my perspective! The seniors' training classes are
                specifically designed for our needs and abilities, and the instructors are patient and encouraging.
              </div>
              <div id="full-stars-example">
                <div className="rating-group">
                  <label aria-label="1 star" className="rating__label" for="rating-1"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-1" value="1" type="radio" />
                  <label aria-label="2 stars" className="rating__label" for="rating-2"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-2" value="2" type="radio" />
                  <label aria-label="3 stars" className="rating__label" for="rating-3"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-3" value="3" type="radio" checked />
                  <label aria-label="4 stars" className="rating__label" for="rating-4"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-4" value="4" type="radio" />
                  <label aria-label="5 stars" className="rating__label" for="rating-5"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-5" value="5" type="radio" />
                </div>
              </div>
            </div>
          </div>
          <div className="owl-item mt-5">
            <div className="card">
              {/* <div className="img-card">
                <img src={image1} alt="" />
              </div> */}
              <h2 className="testimonilaname">Ananya Ahuja</h2>
              <div className="testimonial mt-4 mb-2">
                I'm a new mom, and Edge has been a lifesaver for me. The pre and post-natal
                training classes have helped me stay fit and healthy during and after pregnancy.
                The instructors are experts in their field, and they create a supportive and welcoming
                environment.
              </div>
              <div id="full-stars-example">
                <div className="rating-group">
                  <label aria-label="1 star" className="rating__label" for="rating-1"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-1" value="1" type="radio" />
                  <label aria-label="2 stars" className="rating__label" for="rating-2"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-2" value="2" type="radio" />
                  <label aria-label="3 stars" className="rating__label" for="rating-3"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-3" value="3" type="radio" checked />
                  <label aria-label="4 stars" className="rating__label" for="rating-4"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-4" value="4" type="radio" />
                  <label aria-label="5 stars" className="rating__label" for="rating-5"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-5" value="5" type="radio" />
                </div>
              </div>
            </div>
          </div>
          <div className="owl-item mt-5">
            <div className="card">
              {/* <div className="img-card">
                <img src={image1} alt="" />
              </div> */}
              <h2 className="testimonilaname">Dinesh Rana</h2>
              <div className="testimonial mt-4 mb-2">
                As a busy parent, it's important to me that my kids have a fun and
                safe place to work out. Edge Fitness Center is the perfect fit for our family.
                The kids' training programs are designed for all fitness levels, and the coaches are
                always there to supervise and support the kids.
              </div>
              <div id="full-stars-example">
                <div className="rating-group">
                  <label aria-label="1 star" className="rating__label" for="rating-1"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-1" value="1" type="radio" />
                  <label aria-label="2 stars" className="rating__label" for="rating-2"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-2" value="2" type="radio" />
                  <label aria-label="3 stars" className="rating__label" for="rating-3"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-3" value="3" type="radio" checked />
                  <label aria-label="4 stars" className="rating__label" for="rating-4"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-4" value="4" type="radio" />
                  <label aria-label="5 stars" className="rating__label" for="rating-5"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-5" value="5" type="radio" />
                </div>
              </div>
            </div>
          </div>
          <div className="owl-item mt-5">
            <div className="card">
              {/* <div className="img-card">
                <img src={image1} alt="" />
              </div> */}
              <h2 className="testimonilaname">Abhilash Roy</h2>
              <div className="testimonial mt-4 mb-2">
                Edge Fitness Center has helped me achieve my weight loss goals.
                The coaches are knowledgeable and supportive, and they create a challenging and
                motivating environment. I've learned so much about fitness and nutrition since I started
                training at Edge.
              </div>
              <div id="full-stars-example">
                <div className="rating-group">
                  <label aria-label="1 star" className="rating__label" for="rating-1"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-1" value="1" type="radio" />
                  <label aria-label="2 stars" className="rating__label" for="rating-2"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-2" value="2" type="radio" />
                  <label aria-label="3 stars" className="rating__label" for="rating-3"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-3" value="3" type="radio" checked />
                  <label aria-label="4 stars" className="rating__label" for="rating-4"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-4" value="4" type="radio" />
                  <label aria-label="5 stars" className="rating__label" for="rating-5"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-5" value="5" type="radio" />
                </div>
              </div>
            </div>
          </div>
          <div className="owl-item mt-5">
            <div className="card">
              {/* <div className="img-card">
                <img src={image1} alt="" />
              </div> */}
              <h2 className="testimonilaname">Rushi</h2>
              <div className="testimonial mt-4 mb-2">
                Edge is a great place to work out. The space is huge, and there's a
                lot of equipment to choose from. The coaches are friendly and helpful.
                I've been a member for a few months now, and I'm really happy with the results I've seen.
                I'm stronger and fitter than ever before.
              </div>
              <div id="full-stars-example">
                <div className="rating-group">
                  <label aria-label="1 star" className="rating__label" for="rating-1"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-1" value="1" type="radio" />
                  <label aria-label="2 stars" className="rating__label" for="rating-2"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-2" value="2" type="radio" />
                  <label aria-label="3 stars" className="rating__label" for="rating-3"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-3" value="3" type="radio" checked />
                  <label aria-label="4 stars" className="rating__label" for="rating-4"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-4" value="4" type="radio" />
                  <label aria-label="5 stars" className="rating__label" for="rating-5"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-5" value="5" type="radio" />
                </div>
              </div>
            </div>
          </div>
          <div className="owl-item mt-5">
            <div className="card">
              {/* <div className="img-card">
                <img src={image1} alt="" />
              </div> */}
              <h2 className="testimonilaname">Prashant Patil</h2>
              <div className="testimonial mt-4 mb-2">
                I was devastated when I suffered a serious knee injury last year.
                I'm a professional athlete, and my career was on the line. But thanks
                to the coaches at Edge, are experts in injury rehab. They designed a personalized
                training program that helped me regain my strength, range of motion, and confidence.
              </div>
              <div id="full-stars-example">
                <div className="rating-group">
                  <label aria-label="1 star" className="rating__label" for="rating-1"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-1" value="1" type="radio" />
                  <label aria-label="2 stars" className="rating__label" for="rating-2"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-2" value="2" type="radio" />
                  <label aria-label="3 stars" className="rating__label" for="rating-3"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-3" value="3" type="radio" checked />
                  <label aria-label="4 stars" className="rating__label" for="rating-4"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-4" value="4" type="radio" />
                  <label aria-label="5 stars" className="rating__label" for="rating-5"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-5" value="5" type="radio" />
                </div>
              </div>
            </div>
          </div>
          <div className="owl-item mt-5" >
            <div className="card">
              {/* <div className="img-card">
                <img src={image1} alt="" />
              </div> */}
              <h2 className="testimonilaname">Yogesh Nagapure</h2>
              <div className="testimonial mt-4 mb-2">
                I'm really happy with the progress I've made since I started training at Edge.
                I also love the fact that Edge offers a variety of classes, including strength and conditioning,
                yoga, and Pilates. I've been able to find a class that fits my needs and goals, and I'm really enjoying
                the workouts.
              </div>
              <div id="full-stars-example">
                <div className="rating-group">
                  <label aria-label="1 star" className="rating__label" for="rating-1"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-1" value="1" type="radio" />
                  <label aria-label="2 stars" className="rating__label" for="rating-2"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-2" value="2" type="radio" />
                  <label aria-label="3 stars" className="rating__label" for="rating-3"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-3" value="3" type="radio" checked />
                  <label aria-label="4 stars" className="rating__label" for="rating-4"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-4" value="4" type="radio" />
                  <label aria-label="5 stars" className="rating__label" for="rating-5"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input className="rating__input" name="rating" id="rating-5" value="5" type="radio" />
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
    </>
  );
};
export default Testimonoial;