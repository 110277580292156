
import HeaderSliderSection from '../Componants/HeaderSliderSection';
import ContentDetailSection from '../Componants/ContentDetailSection';
import Contactus from '../Componants/Contactus';
import Faq from '../Componants/Faq';
import Services from '../Componants/Services';
import Testimonoial from '../Componants/Testimonial';
// import Testimonoialvideo from '../Componants/Testimonialvideosection';
import Ourcoache from '../Componants/Ourcoache';
import Navbar from '../Componants/Navbar';
import Footer from '../Componants/Footer';
import '../Componants/testimonial.css';
import { Link } from 'react-router-dom';

const Home = () => {
    document.title = "Edge";
    return (
        <>
          <Navbar/>
            <HeaderSliderSection />
            <ContentDetailSection />
            <Ourcoache />
             {/* <Testimonoialvideo/> */}
            <Testimonoial />
            <Services />
            <Faq />
            <Contactus />
            <Footer/>
            {/* <Link to="/contactus" class="back-to-top d-flex align-items-center justify-content-center" > 
            <i class="bi bi-arrow-right-short"></i></Link> */}
            <a href="https://wa.me/9226089711" target='_black' class="btn-whatsapp-pulse">
                <i class="fab fa-whatsapp"></i>
            </a>
        </>
    )
}
export default Home;