
import Veryfiotp from '../Componants/Veryfiotp';
// import AddUserform from '../Componants/AddUserform';
import '../Pages/Enquiryform.css';

function EnquiryForm() {
  return (
    <>
      {/* <div className="page">
      <div className="centered">GO</div>
        <main className="main page__main">
          <form className="login-form main__login-form" action="javascript:void(0)" method="POST">
            <div className="row">
                        <div className="col">
                        <label className='animate__animated animate__fadeInDown'>Mobile No.</label>
                            <input type="text" className="form-control" id="email" placeholder="Enter email" name="email" />
                        </div>
                        <div className="col">
                        <label className='animate__animated animate__fadeInDown'>Mobile No.</label>
                            <input type="password" className="form-control" placeholder="Enter password" name="pswd"/>
                        </div>
                    </div>
           
          </form>
        </main>
      </div> */}
  <Veryfiotp/>
    </>
  );
}

export default EnquiryForm;