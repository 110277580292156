
// import { Link } from 'react-router-dom';
import image7 from '../images/Services/service 6_6_11zon.webp';
import image8 from '../images/Services/service 8_8_11zon.webp';
import image1 from '../images/Services/service 1_1_11zon.webp';
import image3 from '../images/Services/service 2_2_11zon.webp';
import image2 from '../images/Services/service 3_3_11zon.webp';
import image5 from '../images/Services/service 5_5_11zon.webp';
import image6 from '../images/Services/service 7_7_11zon.webp';
import image4 from '../images/Services/service 4_4_11zon.webp';

import rightdirection from '../images/Icon/rightdirection.png';
import leftdirection from '../images/Icon/leftdirection.png';
import updirection from '../images/Icon/updireaction.png';

export const ContentDetailSection = () => {
    return (
        <section id="about" class="about">
            <center> <h1 className='servicesheading'>Services</h1> </center>
            <div class="container d-none d-md-block d-lg-block">
                <div className="row mt-5">
                    <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="single-well">
                            <img src={image1} alt="Contaentdetail1" className="contentsmallimages" />
                        </div>
                        <div className="single-well mt-5">
                            <h4 className="sec-head d-flex">
                                <span className="fitnessdetailnuber">01</span>
                                <span class="strandconditon" >Sport-Specific Training: </span>
                                <img src={updirection} alt="test" className="directionicon sporttringicon" />
                            </h4>
                            <p>
                                We tailored sport-specific training programs to help you improve your performance in your chosen sport. We offer programs for a variety of sports, including basketball, baseball, football, soccer, badminton, and track and field. Our experienced coaches will help you develop the skills and fitness you need to succeed.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="single-well ">
                            <h4 className="sec-head d-flex">
                                <span className="fitnessdetailnuber">02</span>
                                <span class="strandconditon">
                                    Strength & Conditioning:
                                </span>
                                {/* <span class='bi bi-chevron-double-right iconcontent mrleaft2'></span> */}
                                <img src={rightdirection} alt="test" className="directionicon strengthicon" />

                            </h4>
                            <p>
                                Our strength and conditioning programs are designed to help you build muscle, increase strength, and improve your overall fitness. Our programs are based on the latest scientific research and are led by certified personal trainers.   </p>
                        </div>
                        <div className="single-well mt-5">
                            <img src={image2} alt="Contaentdetail2" className="contentsmallimages" />
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="single-well">
                            <img src={image3} alt="Contaentdetail3" className="contentsmallimages" />
                        </div>
                        <div className="single-well">
                            <h4 className="sec-head d-flex">
                                <span className="fitnessdetailnuber">03</span>
                                <span class="fatloss" > Fat Loss: </span>
                            </h4>
                            <p>
                                Edge’s fat loss programs are designed to help you lose weight and body fat healthily and sustainably. We offer a variety of programs to choose from, so you can find one that fits your needs and goals.
                            </p>
                            <img src={leftdirection} alt="test" className="directionicon " />
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="well-left">
                            <div className="single-well">
                                <img src={image4} alt="Contaentdetail4" className="contentsmallimages2" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="well-middle">
                            <div className="single-well">
                                {/* <a href="#"> */}
                                <h4 className="sec-head d-flex">
                                    <span className="fitnessdetailnuber">04</span>
                                    <span class="fatloss"> Personal Training: </span>
                                </h4>
                                {/* </a> */}
                                <p>
                                    Our personal training programs are customized to help you reach your fitness goals in a personalized and one-on-one setting. Our certified personal trainers will work with you to develop a fitness plan that is tailored to your individual needs and goals
                                </p>
                                <img src={leftdirection} alt="test" className="directionicon" />
                            </div>
                        </div>
                    </div>
                </div>

                <hr className="borderline " />
                <div className="row">
                    <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="single-well">
                            <img src={image7} alt="Contaentdetail7" className="contentsmallimages" />
                        </div>
                        <div className="single-well mt-5">
                            <h4 className="sec-head d-flex">
                                <span className="fitnessdetailnuber">07</span>
                                <span class="strandconditon"> Injury<br /> Rehab: </span>
                                <img src={rightdirection} alt="test" className="directionicon sporttringicon" />
                            </h4>
                            <p>
                                Injury Rehab programs are personalized by experts to help you recover from an injury and return to your full level of fitness. We offer a variety of programs to choose from, depending on the type of injury you have.  </p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="single-well ">
                            <h4 className="sec-head d-flex">
                                <span className="fitnessdetailnuber">06</span>
                                <span class="strandconditon"> Senior & Kid Batches:</span>
                                <img src={leftdirection} alt="test" className="directionicon sporttringicon" />
                            </h4>
                            <p>
                                We offer specialized fitness classes for seniors and kids. Our senior classes are designed to help seniors improve their strength, balance, and flexibility. Our kid classes are designed to help kids develop fundamental movement skills and a love of fitness.  </p>
                        </div>
                        <div className="single-well mt-5">
                            <img src={image6} alt="Contaentdetail6" className="contentsmallimages" />
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="single-well">
                            <img src={image5} alt="Contaentdetail5" className="contentsmallimages" />
                        </div>
                        <div className="single-well mt-5">
                            <h4 className="sec-head d-flex">
                                <span className="fitnessdetailnuber">05</span>
                                <span class="strandconditon"> Pre- & Post-Pregnancy:</span>
                                <img src={updirection} alt="test" className="directionicon sporttringicon" />
                            </h4>
                            <p>
                                Edge offers special fitness programs for pre- and post-pregnancy women first time in Pune. Our pre-pregnancy programs are specially crafted to assist women in their journey of preparing for childbirth, while our post-pregnancy programs are diligently designed to support women in their recovery after childbirth and aid them in regaining their shape. </p>
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="well-left">
                            <div className="single-well">

                                <img src={image8} alt="Contaentdetail8" className="contentsmallimages2" />

                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="well-middle">
                            <div className="single-well">

                                <h4 className="sec-head d-flex">
                                    <span className="fitnessdetailnuber">08</span>
                                    <span class="strandconditon">Nutrition <br /> Counseling:</span>

                                </h4>

                                <p>
                                    We offer nutrition counseling to help you achieve your fitness goals. Our registered dietitians will work with you to develop a healthy and balanced eating plan that meets your individual needs and goals.   </p>
                                <img src={leftdirection} alt="test" className="directionicon" />
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="borderline " />
            </div>

            {/* mobileno */}
            <div class="container d-lg-none">
                {/* <p>
                    Urbanfit Edge – Pune’s Largest (10000 sq. ft.) sports athletic training center that offers a range of services
                    to help you reach your fitness goals. Our Services includes:
                </p>
                <ul>
                    <li>   <img src={rightdirection} alt="test" className="directionicon strengthicon" /> 1. Sports Specific Training</li>
                    <li>   <img src={rightdirection} alt="test" className="directionicon strengthicon" /> 2. Strength & Conditioning</li>
                    <li>  <img src={rightdirection} alt="test" className="directionicon strengthicon" /> 2. Strength & Conditioning</li>
                    <li>  <img src={rightdirection} alt="test" className="directionicon strengthicon" /> 3. Fat Loss Training</li>
                    <li>  <img src={rightdirection} alt="test" className="directionicon strengthicon" /> 4. Personal Training</li>
                    <li>   <img src={rightdirection} alt="test" className="directionicon strengthicon" /> 5. Pre & Post Pregnancy Training</li>
                    <li>  <img src={rightdirection} alt="test" className="directionicon strengthicon" /> 6. Senior & Kids Batches</li>
                    <li>  <img src={rightdirection} alt="test" className="directionicon strengthicon" /> 7. Injury Rehab</li>
                    <li>  <img src={rightdirection} alt="test" className="directionicon strengthicon" /> 8. Nutrition Consultation</li>

                </ul> */}

                <div class="row">
                    <div class="col-md-12 col-sm-12 col-xs-12">
                        <div class="section-headline text-center">
                            <p>
                                Urbanfit Edge – Pune’s Largest (10000 sq. ft.) sports athletic training center that offers a range of services
                                to help you reach your fitness goals. Our Services includes:
                            </p>
                        </div>
                        <div className="footer-contacts">
                            <p>
                                <img src={rightdirection} alt="test" className='mobileviewicon' />
                                <span className="footerpointes"> Sports Specific Training</span>
                            </p>
                            <p>
                                <img src={rightdirection} alt="test" className='mobileviewicon' />
                                <span className="footerpointes"> Strength & Conditioning</span>
                            </p>
                            <p>
                                <img src={rightdirection} alt="test" className='mobileviewicon' />
                                <span className="footerpointes"> Fat Loss Training</span>
                            </p>
                            <p>
                                <img src={rightdirection} alt="test" className='mobileviewicon' />
                                <span className="footerpointes"> Personal Training</span>
                            </p>
                            <p>
                                <img src={rightdirection} alt="test" className='mobileviewicon' />
                                <span className="footerpointes"> Pre & Post Pregnancy Training </span>
                            </p>
                            <p>
                                <img src={rightdirection} alt="test" className='mobileviewicon' />
                                <span className="footerpointes"> Senior & Kids Batches</span>
                            </p>
                            <p>
                                <img src={rightdirection} alt="test" className='mobileviewicon' />
                                <span className="footerpointes">Injury Rehab</span>
                            </p>
                            <p>
                                <img src={rightdirection} alt="test" className='mobileviewicon' />
                                <span className="footerpointes"> Nutrition Consultation</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
};
export default ContentDetailSection;