import React from "react";
import image1 from '../images/Coaches/Kirat.webp';
// import image2 from '../images/Coaches/Sonal.webp';
import image3 from '../images/Coaches/Kanchan.webp';
import image4 from '../images/Coaches/Gandhar.webp';
import image5 from '../images/Coaches/pankaj.webp';

export const Ourcoache = () => {
  return (
    <>
      <section id="team" className="team">
        <div className="container">
          <div className="section-title">
            <h2>Our Coaches</h2>
          </div>
          <div className="row">
            <div className=" col-xl-6 col-lg-6 col-md-6 " data-wow-delay="0.1s">
              <div className="member">
                <img src={image1} className="img-fluid memberimg" alt="" />
                <div className="member-info ">
                  <div className="member-info-content">
                    <h4>Kirat Shergill</h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 " data-wow-delay="0.1s">
              <div className="single-well mt-5">
                <h4 className="sec-head text-center">
                  <span className="foundreandcoche">Kirat Shergill</span><br />
                  <span class="strandconditon"> Head of performance</span>
                </h4>
                <p>
                  Mr. Kirat is a well-known figure in the Pune fitness and athletic community.
                  He has an impressive track record that only a few trainers in Pune can match.
                  Kirat has previously run successful fitness centers, such as Spiritual Fitness,
                  which was his own venture and transformed the fitness industry in Pune. The entire concept
                  behind EDGE PERFORMANCE Center is Kirat's ideology that revolutionized the fitness industry.
                </p>
              </div>
            </div>
          </div>
          <div className="row coachimgrow">
            {/* <div className="col-sm-6 col-xl-3 col-lg-3 col-md-6 ourcoacheimg">
              <div className="member">
                <img src={image1} className="img-fluid memberimg" alt="" />
                <div className="member-info">
                  <div className="member-info-content">
                    <h4>Kirat Shergill</h4>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className=" col-sm-6 col-xl-3 col-lg-3 col-md-6 ourcoacheimg" data-wow-delay="0.1s">
              <div className="member">
                <img src={image2} className="img-fluid memberimg" alt="" />
                <div className="member-info">
                  <div className="member-info-content">
                    <h4>Sonal</h4>
                  </div>
                </div>
              </div>
            </div> */}
            <div className=" col-sm-6 col-xl-3 col-lg-3 col-md-6 ourcoacheimg" data-wow-delay="0.1s">
              <div className="member">
                <img src={image3} className="img-fluid memberimg" alt="" />
                <div className="member-info">
                  <div className="member-info-content">
                    <h4>Kanchan</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-3 col-lg-3 col-md-6 ourcoacheimg" data-wow-delay="0.1s">
              <div className="member">
                <img src={image4} className="img-fluid memberimg" alt="" />
                <div className="member-info">
                  <div className="member-info-content">
                    <h4>Gandhar</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-sm-6 col-xl-3 col-lg-3 col-md-6 ourcoacheimg" data-wow-delay="0.1s">
              <div className="member">
                <img src={image5} className="img-fluid memberimg" alt="" />
                <div className="member-info">
                  <div className="member-info-content">
                    <h4>Pankaj</h4>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-sm-6 col-xl-3 col-lg-3 col-md-6 ourcoacheimg" data-wow-delay="0.2s">
              <div className="member">
                <img src={image3} className="img-fluid memberimg" alt="" /> 
              </div>
            </div>
               <div className="col-sm-6 col-xl-3 col-lg-3 col-md-6 ourcoacheimg" data-wow-delay="0.2s">
              <div className="member">
                <img src={image4} className="img-fluid memberimg" alt="" /> 
              </div>
            </div> */}
            <hr className="borderline " />
          </div>

        </div>
      </section>
    </>
  );
};
export default Ourcoache;